import { USER_EXPORT_FILTER } from '@/constants'

const state = {
  userBomValidations: {
    results: [],
    filteredResults: [],
    isInProgress: true,
    size: 0,
    selectedUserExport: {},
    selectedUserExportType: '',
    filterType: USER_EXPORT_FILTER.NONE,
    filters: []
  },
  filteredTestCases: {
    results: [],
    filteredResults: [],
    isInProgress: true,
    size: 0,
    filterType: USER_EXPORT_FILTER.NONE,
    filters: []
  },
  testCaseFilters: {
    creator: '',
    date: '',
    search: '',
    isPinned: false,
    refresh: false
  },
  testCasePageable: {
    page: 0,
    pageSize: 20,
    totalPages: 0,
    totalElements: 0
  },
  isNewValidationDialogShown: false,
  validationsSearchString: '',
  bomValidations: [],
  bomCurrentElements: { hasAnyErrorTypeResults: false },
  bomValidationsInProgress: [],
  testCases: [],
  testCaseValidations: [],
  currentBomValidation: null,
  bomValidationComponents: [],
  isBomValidationLoading: false,
  areTestCasesLoading: false
}

export default state
