import state from '@/store/modules/validations/state'
import { BOM_VALIDATION_IN_PROGRESS } from '@/constants'

let tempBomValidationsInProgress = []

const getters = {
  getCurrentBomValidation({ currentBomValidation = null } = {}) {
    return currentBomValidation
  },
  getChangedStatus() {
    if (!tempBomValidationsInProgress?.length) {
      tempBomValidationsInProgress = state.bomValidationsInProgress
    }

    const changedStatus = tempBomValidationsInProgress.map(tempBomValidation => {
      const bomValidations = state.bomValidations
        .find(bomValidation => bomValidation.id === tempBomValidation.id)
      return bomValidations.status !== BOM_VALIDATION_IN_PROGRESS ? bomValidations : null
    }).filter(status => status !== null)

    tempBomValidationsInProgress = state.bomValidationsInProgress
    return changedStatus
  }
}

export default getters
