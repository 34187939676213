import { EXTRA_PM_TYPES } from '@/constants'
import {
  SELECT_PRODUCT_MODEL,
  SELECT_SEARCH_PRODUCT_MODEL,
  SET_PRODUCT_MODEL_COUNTS,
  SET_PRODUCT_MODEL_COUNTS_LOADING_STATE,
  SET_PRODUCT_MODEL_LOADING_STATE,
  UPDATE_PRODUCT_MODELS_LIST,
  UPDATE_SEARCH_PRODUCT_MODELS_LIST
} from './mutationTypes'

const mutations = {
  [SELECT_PRODUCT_MODEL](state, productModelEncodedBusinessName = null) {
    state.selectedProductModelEncodedBusinessName = productModelEncodedBusinessName
  },
  [SELECT_SEARCH_PRODUCT_MODEL](state, selectedSearchProductModel = {}) {
    if (selectedSearchProductModel.onDemandExportMetadata) {
      state.selectedSearchProductModel = {
        ...selectedSearchProductModel,
        displayName: selectedSearchProductModel.onDemandExportMetadata.exportName
      }
    }
    else {
      state.selectedSearchProductModel = selectedSearchProductModel
    }
  },
  [UPDATE_PRODUCT_MODELS_LIST](state, productModels = []) {
    state.productModels = productModels
  },
  [UPDATE_SEARCH_PRODUCT_MODELS_LIST](state, { productModels, userExports }) {
    const finalProductModelsList = (productModels.value || []).map(productModel => {
      productModel.displayName = productModel.businessName
      return productModel
    })

    const setDisplayName = list => {
      list.forEach(item => {
        item.displayName = item.name
      })
    }

    setDisplayName(userExports.value?.bom || [])
    setDisplayName(userExports.value?.basic || [])

    const complementProductModels = EXTRA_PM_TYPES.map(extraPmData => ({
      ...extraPmData,
      child: (userExports.value?.[extraPmData.type] || [])
    }))

    state.searchProductModels = finalProductModelsList.concat(complementProductModels)
  },
  [SET_PRODUCT_MODEL_LOADING_STATE](state, loading = false) {
    state.areProductModelsLoading = loading
  },
  [SET_PRODUCT_MODEL_COUNTS](state, counts = { setupCount: 0, completedCount: 0 }) {
    const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }
    const now = new Date()
    state.productModelsAutoBom.lastUpdate = new Intl.DateTimeFormat('en-GB', dateOptions).format(now)
    state.productModelsAutoBom.counts = counts
  },
  [SET_PRODUCT_MODEL_COUNTS_LOADING_STATE](state, loading = false) {
    state.productModelsAutoBom.loading = loading
  }
}

export default mutations
