import {
  ON_DEMAND_EXPORTS_ROUTE,
  RULE_VALIDATIONS_ROUTE
} from '@/router/routeNames'

// Children routes
import onDemandExports from './onDemandExports'
import bom from './bom'

const RuleValidations = () => import(
  /* webpackChunkName: "rule-validation" */ '@/views/RuleValidations.vue'
)

const routes = [
  {
    path: RULE_VALIDATIONS_ROUTE.path,
    name: RULE_VALIDATIONS_ROUTE.name,
    component: RuleValidations,
    redirect: {
      name: ON_DEMAND_EXPORTS_ROUTE.name
    },
    meta: {
      title: RULE_VALIDATIONS_ROUTE.title,
      isAppHeaderVisible: true,
      isAuthRequired: true
    },
    children: [
      ...onDemandExports,
      ...bom
    ]
  }
]

export default routes
