export const SET_BOM_VALIDATIONS = 'SET_BOM_VALIDATIONS'
export const SET_IN_PROGRESS_BOM_VALIDATIONS = 'SET_IN_PROGRESS_BOM_VALIDATIONS'
export const SET_CURRENT_BOM_VALIDATION = 'SET_CURRENT_BOM_VALIDATION'
export const SET_IS_NEW_VALIDATION_DIALOG_SHOWN = 'SET_IS_NEW_VALIDATION_DIALOG_SHOWN'
export const SET_TEST_CASES = 'SET_TEST_CASES'
export const SET_TEST_CASE_VALIDATIONS = 'SET_TEST_CASE_VALIDATIONS'
export const SET_VALIDATIONS_SEARCH_STRING = 'SET_VALIDATIONS_SEARCH_STRING'
export const SET_BOM_VALIDATION_COMPONENTS = 'SET_BOM_VALIDATION_COMPONENTS'
export const SET_BOM_VALIDATION_IS_LOADING = 'SET_BOM_VALIDATION_IS_LOADING'
export const SET_FILTER_USER_VALIDATIONS = 'SET_FILTER_USER_VALIDATIONS'
export const SET_FILTERED_USER_VALIDATIONS = 'SET_FILTERED_USER_VALIDATIONS'
export const HANDLE_INITIAL_STATE_SELECTED_EXPORT = 'HANDLE_INITIAL_STATE_SELECTED_EXPORT'
export const SET_FILTER_TEST_CASES = 'SET_FILTER_TEST_CASES'
export const ARE_TEST_CASES_LOADING = 'ARE_TEST_CASES_LOADING'
export const SET_EXPORT_DATA_VISIBILITY = 'SET_EXPORT_DATA_VISIBILITY'
export const SET_TEST_CASE_FILTERS = 'SET_TEST_CASE_FILTERS'
export const SET_TEST_CASE_PAGE = 'SET_TEST_CASE_PAGE'
export const RESET_TEST_CASE_FILTERS = 'RESET_TEST_CASE_FILTERS'
