export const ADD_RULE_TO_IGNORE_LIST = 'ADD_RULE_TO_IGNORE_LIST'
export const CLEAR_LOCKED_CHOICES_IDS = 'CLEAR_LOCKED_CHOICES_IDS'
export const CLEAR_POSSIBLE_COMBINATION_CHOICES_IDS = 'CLEAR_POSSIBLE_COMBINATION_CHOICES_IDS'
export const CLEAR_SELECTED_CHOICES = 'CLEAR_SELECTED_CHOICES'
export const REMOVE_CHOICE_FROM_VALIDATION_RESULTS = 'REMOVE_CHOICE_FROM_VALIDATION_RESULTS'
export const REMOVE_RULE_FROM_IGNORE_LIST = 'REMOVE_RULE_FROM_IGNORE_LIST'
export const SET_ARE_OPTIONS_LOADING = 'SET_ARE_OPTIONS_LOADING'
export const SET_ARE_INVALID_OPTIONS_LOADING = 'SET_ARE_INVALID_OPTIONS_LOADING'
export const SET_CONFIGURATIONS_SEARCH_STRING = 'SET_CONFIGURATIONS_SEARCH_STRING'
export const SET_CURRENT_CONFIGURATION = 'SET_CURRENT_CONFIGURATION'
export const SET_LOCKED_CHOICES_IDS = 'SET_LOCKED_CHOICES_IDS'
export const SET_POSSIBLE_COMBINATION_CHOICES_IDS = 'SET_POSSIBLE_COMBINATION_CHOICES_IDS'
export const SET_OPTIONS = 'SET_OPTIONS'
export const SET_INVALID_OPTIONS = 'SET_INVALID_OPTIONS'
export const REMOVE_CHOICE_FROM_INVALID_OPTIONS = 'REMOVE_CHOICE_FROM_INVALID_OPTIONS'
export const SET_SELECTED_CHOICES = 'SET_SELECTED_CHOICES'
export const SET_VALIDATION = 'SET_VALIDATION'
export const TOGGLE_CHOICE_LOCK = 'TOGGLE_CHOICE_LOCK'
export const TOGGLE_CHOICE_SELECTION = 'TOGGLE_CHOICE_SELECTION'
export const CHANGED_CONFIGURATION = 'CHANGED_CONFIGURATION'
export const UPDATE_POSSIBLE_COMBINATIONS_AFTER_VALIDATION = 'UPDATE_POSSIBLE_COMBINATIONS_AFTER_VALIDATION'
export const SET_CONFIG_STRUCTURE_OPTIONS = 'setConfigStructureOptions'
export const SET_CONFIG_SPECIFICATION_FILTER_OPTIONS = 'setConfigSpecificationFilterOptions'
export const SET_CLEAR_CONFIGURATION_OPTIONS_QUERY = 'setClearConfigurationOptionsQuery'
