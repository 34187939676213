import { USER_MANAGEMENT_ROUTE } from '@/router/routeNames'

const UserManagement = () => import(/* webpackChunkName: "user-management" */ '@/views/UserManagement.vue')

export const routes = [
  {
    path: USER_MANAGEMENT_ROUTE.path,
    name: USER_MANAGEMENT_ROUTE.name,
    component: UserManagement,
    meta: {
      title: USER_MANAGEMENT_ROUTE.title,
      authorities: ['LIST_USERS'],
      isAppHeaderVisible: true,
      isAuthRequired: true
    }
  }
]

export default routes
