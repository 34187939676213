import { ON_DEMAND_BOM_VALIDATIONS_ROUTE } from '@/router/routeNames'

const OnDemandValidations = () => import(
  /* webpackChunkName: "on-demand-exports-validations" */
  '@/components/rule-validation/validations/OnDemandValidations'
)

const routes = [
  {
    path: ON_DEMAND_BOM_VALIDATIONS_ROUTE.path,
    name: ON_DEMAND_BOM_VALIDATIONS_ROUTE.name,
    component: OnDemandValidations,
    meta: {
      appFeatureSubRoute: ON_DEMAND_BOM_VALIDATIONS_ROUTE,
      title: ON_DEMAND_BOM_VALIDATIONS_ROUTE.title
    }
  }
]

export default routes
