import {
  getTestCases,
  getTestCasesValidations,
  getUserExportsList,
  getUserExportsListByType,
  getValidations
} from '@/api'
import {
  SET_USER_EXPORTS,
  SET_VALIDATION,
  SET_VALIDATIONS_EXPORTS,
  UPDATE_USER_EXPORTS_IN_PROGRESS,
  UPDATE_VALIDATIONS_IN_PROGRESS,
  SET_VALIDATION_ERROR_AMOUNT,
  SET_SUMMARY_DATA,
  SET_TEST_CASES_FILES,
  SET_FILTERED_USER_EXPORTS,
  SET_FILTER_TYPE,
  SET_USER_EXPORTS_BY_TYPE,
  SET_USER_EXPORTS_BY_TYPES
} from '@/store/modules/rule-validation/mutationTypes'
import { USER_EXPORT_FILTER } from '@/constants'

const actions = {
  clearFilterType({ commit }) {
    commit(SET_FILTER_TYPE, USER_EXPORT_FILTER.NONE)
  },
  async fetchUserExports({ commit }) {
    commit(UPDATE_USER_EXPORTS_IN_PROGRESS, true)

    try {
      const content = await getUserExportsList()

      commit(SET_USER_EXPORTS, Object.freeze(content))
      commit(SET_FILTERED_USER_EXPORTS)
      return content
    }
    finally {
      commit(UPDATE_USER_EXPORTS_IN_PROGRESS, false)
    }
  },
  async fetchUserExportsByType({ commit }, type) {
    commit(UPDATE_USER_EXPORTS_IN_PROGRESS, true)

    try {
      const content = await getUserExportsListByType(type)

      for (const text of Object.values(content)) {
        const { onDemandExportMetadata: { exportName } } = text
        text.businessName = exportName
      }

      commit(SET_USER_EXPORTS_BY_TYPE, Object.freeze(content))
      commit(SET_FILTERED_USER_EXPORTS)
      return content
    }
    finally {
      commit(UPDATE_USER_EXPORTS_IN_PROGRESS, false)
    }
  },
  async fetchUserExportsByTypes({ commit }) {
    commit(UPDATE_USER_EXPORTS_IN_PROGRESS, true)

    try {
      const basicExports = await getUserExportsListByType('basic')
      const bomExports = await getUserExportsListByType('bom')
      const content = { basic: basicExports, bom: bomExports }

      commit(SET_USER_EXPORTS_BY_TYPES, Object.freeze(content))
      commit(SET_FILTERED_USER_EXPORTS)
      return content
    }
    finally {
      commit(UPDATE_USER_EXPORTS_IN_PROGRESS, false)
    }
  },
  async fetchValidations({ state, commit }) {
    const { id } = state.userExports.selectedUserExport

    commit(UPDATE_VALIDATIONS_IN_PROGRESS, true)

    try {
      const data = await getValidations(id) || []
      commit(SET_VALIDATIONS_EXPORTS, Object.freeze(data))
      return data
    }
    finally {
      commit(UPDATE_VALIDATIONS_IN_PROGRESS, false)
    }
  },
  async fetchTestCasesValidations({ state, commit }) {
    const { id } = state.userExports.selectedUserExport

    commit(UPDATE_VALIDATIONS_IN_PROGRESS, true)

    try {
      const data = await getTestCasesValidations(id) || []
      commit(SET_VALIDATIONS_EXPORTS, Object.freeze(data))
      return data
    }
    finally {
      commit(UPDATE_VALIDATIONS_IN_PROGRESS, false)
    }
  },
  async getTestCasesFiles({ commit }) {
    const queryParams = new URLSearchParams({
      page: 0,
      pageSize: 200
    })
    const { elements } = await getTestCases(queryParams.toString())

    commit(SET_TEST_CASES_FILES, elements)

    return elements
  },
  setValidationResultsData({ commit }, value) {
    const { validation, summaryData } = value
    commit(SET_VALIDATION, validation)
    commit(SET_VALIDATION_ERROR_AMOUNT)
    commit(SET_SUMMARY_DATA, summaryData)
  }
}

export default actions
