import {
  ADD_RULE_TO_IGNORE_LIST,
  CLEAR_LOCKED_CHOICES_IDS,
  CLEAR_POSSIBLE_COMBINATION_CHOICES_IDS,
  CLEAR_SELECTED_CHOICES,
  REMOVE_CHOICE_FROM_VALIDATION_RESULTS,
  REMOVE_RULE_FROM_IGNORE_LIST,
  SET_ARE_OPTIONS_LOADING,
  SET_CONFIGURATIONS_SEARCH_STRING,
  SET_CURRENT_CONFIGURATION,
  SET_LOCKED_CHOICES_IDS,
  SET_POSSIBLE_COMBINATION_CHOICES_IDS,
  SET_OPTIONS,
  SET_SELECTED_CHOICES,
  SET_VALIDATION,
  TOGGLE_CHOICE_LOCK,
  TOGGLE_CHOICE_SELECTION,
  UPDATE_POSSIBLE_COMBINATIONS_AFTER_VALIDATION,
  SET_CONFIG_STRUCTURE_OPTIONS,
  SET_CONFIG_SPECIFICATION_FILTER_OPTIONS,
  SET_CLEAR_CONFIGURATION_OPTIONS_QUERY,
  SET_INVALID_OPTIONS,
  SET_ARE_INVALID_OPTIONS_LOADING,
  CHANGED_CONFIGURATION,
  REMOVE_CHOICE_FROM_INVALID_OPTIONS
} from './mutationTypes'

const mutations = {
  [ADD_RULE_TO_IGNORE_LIST]({ currentConfiguration = {} } = {}, rule = {}) {
    const isRuleInIgnoreList = currentConfiguration.ignoreList?.some(ignoreRule => ignoreRule.id === rule.id)

    if (!isRuleInIgnoreList && Object.keys(rule)?.length) {
      currentConfiguration.ignoreList?.push(rule)
    }
  },
  [CLEAR_LOCKED_CHOICES_IDS](state) {
    state.lockedChoicesIds = new Map()
  },
  [CLEAR_POSSIBLE_COMBINATION_CHOICES_IDS]({ possibleCombinationChoicesIds = {} } = {}) {
    possibleCombinationChoicesIds.invalid = new Map()
    possibleCombinationChoicesIds.valid = new Map()
  },
  [CLEAR_SELECTED_CHOICES](state) {
    state.selectedChoices = new Map()
  },
  [REMOVE_CHOICE_FROM_VALIDATION_RESULTS](state, choiceId = '') {
    state.validation.results = state.validation.results?.filter(
      validationResult => validationResult.choiceId !== choiceId
    ) || []

    if (!state.validation.results.length) state.initialState = true
  },
  [REMOVE_RULE_FROM_IGNORE_LIST]({ currentConfiguration = {} } = {}, ruleId = '') {
    currentConfiguration.ignoreList = currentConfiguration.ignoreList?.filter(
      ignoreRule => ignoreRule.id !== ruleId
    ) || []
  },
  [SET_ARE_OPTIONS_LOADING](state, areOptionsLoading = false) {
    state.options.areLoading = areOptionsLoading
  },
  [SET_ARE_INVALID_OPTIONS_LOADING](state, areOptionsLoading = false) {
    state.invalidOptions.areLoading = areOptionsLoading
  },
  [SET_CONFIGURATIONS_SEARCH_STRING](state, searchString = '') {
    state.configurationsSearchString = searchString
  },
  [SET_CURRENT_CONFIGURATION](state, configuration = {}) {
    state.currentConfiguration = configuration
  },
  [SET_LOCKED_CHOICES_IDS]({ lockedChoicesIds = new Map() } = {}, choices = []) {
    for (const choiceElement of choices) {
      const { choice = {}, locked } = choiceElement

      if (locked) {
        if (lockedChoicesIds.has(choice.optionId)) {
          const existingLockedChoicesIds = lockedChoicesIds.get(choice.optionId) || []

          existingLockedChoicesIds.push(choice.id)
          lockedChoicesIds.set(choice.optionId, [...new Set(existingLockedChoicesIds)])
        }
        else {
          lockedChoicesIds.set(choice.optionId, [choice.id])
        }
      }
    }
  },
  [SET_POSSIBLE_COMBINATION_CHOICES_IDS]({ possibleCombinationChoicesIds = {} }, combinations = []) {
    combinations.forEach(({ domainId = '', invalidCombinationChoices = [], validCombinationChoices = [] } = {}) => {
      possibleCombinationChoicesIds.invalid.set(domainId, invalidCombinationChoices)
      possibleCombinationChoicesIds.valid.set(domainId, validCombinationChoices)
    })
  },
  [SET_OPTIONS](state, items = []) {
    state.options.items = items
    state.options.totalSize = items.length
  },
  [SET_INVALID_OPTIONS](state, items = []) {
    const options = state.options.items
    state.invalidOptions.items = options.filter(option => items.some(item => item.optionId === option.id))
    state.invalidOptions.totalSize = state.invalidOptions.items.length
    state.invalidOptions.optionsSize = items.length
  },
  [SET_SELECTED_CHOICES]({ selectedChoices = new Map() } = {}, choices = []) {
    choices.forEach(choice => {
      if (selectedChoices.has(choice.optionId)) {
        const existingSelectedChoices = selectedChoices.get(choice.optionId) || []
        if (!existingSelectedChoices.some(selectedChoice => selectedChoice.id === choice.id)) {
          existingSelectedChoices.push({ id: choice.id, id2: choice.id2, name: choice.name })
          selectedChoices.set(choice.optionId, existingSelectedChoices)
        }
      }
      else {
        selectedChoices.set(
          choice.optionId, [{ id: choice.id, id2: choice.id2, name: choice.name }]
        )
      }
    })
  },
  [SET_VALIDATION](state,
    {
      results = [],
      isInProgress = false,
      showBadge = false,
      isValidValidation = false,
      initialState = true,
      errorCode = null
    } = {}) {
    state.validation = { results, isInProgress, showBadge }
    state.initialState = initialState
    state.isValidValidation = isValidValidation
    state.errorCode = errorCode
  },
  [TOGGLE_CHOICE_LOCK](
    { currentConfiguration = {}, lockedChoicesIds = new Map() } = {},
    { optionId = '', choiceId = '', wasLocked = false } = {}
  ) {
    const lockedChoicesIdsInOption = lockedChoicesIds.get(optionId) || []

    if (wasLocked) {
      const filteredLockedChoicesIds = lockedChoicesIdsInOption.filter(
        existingChoiceId => existingChoiceId !== choiceId
      )

      if (filteredLockedChoicesIds.length) {
        lockedChoicesIds.set(optionId, filteredLockedChoicesIds)
      }
      else {
        lockedChoicesIds.delete(optionId)
      }
    }
    else {
      lockedChoicesIds.set(optionId, [...lockedChoicesIdsInOption, choiceId])
    }

    // Updates lock state for target choice inside currentConfiguration
    for (let i = 0; i < currentConfiguration.selectedChoices?.length; i++) {
      if (currentConfiguration.selectedChoices[i].choice?.id === choiceId) {
        currentConfiguration.selectedChoices[i].locked = !wasLocked
        break
      }
    }
  },
  [TOGGLE_CHOICE_SELECTION](
    { currentConfiguration = {}, selectedChoices = new Map() } = {},
    { optionId = '', choice = {}, wasSelected = false }
  ) {
    const selectedChoicesInOption = selectedChoices.get(optionId) || []

    if (wasSelected) {
      const filteredSelectedChoices = selectedChoicesInOption.filter(
        existingChoice => existingChoice.id !== choice.id
      )

      if (filteredSelectedChoices.length) {
        selectedChoices.set(optionId, filteredSelectedChoices)
      }
      else {
        selectedChoices.delete(optionId)
      }

      // Removes choices from selected inside currentConfiguration
      currentConfiguration.selectedChoices = currentConfiguration.selectedChoices?.filter(
        selectedChoice => selectedChoice.choice.id !== choice.id
      )
    }
    else {
      selectedChoicesInOption.push({
        id: choice.id,
        id2: choice.id2,
        name: choice.name
      })

      selectedChoices.set(optionId, selectedChoicesInOption)

      // Adds choice as selected to currentConfiguration
      currentConfiguration.selectedChoices?.push({ choice, locked: false })
    }
  },
  [UPDATE_POSSIBLE_COMBINATIONS_AFTER_VALIDATION](
    { possibleCombinationChoicesIds = {}, selectedChoices = new Map() } = {},
    validationResults = []
  ) {
    const validationResultsIds = new Map()

    validationResults.forEach(({ optionId = '', choiceId = '' } = {}) => {
      const choicesIds = validationResultsIds.has(optionId)
        ? [...validationResultsIds.get(optionId), choiceId]
        : [choiceId]

      validationResultsIds.set(optionId, choicesIds)
    })

    selectedChoices.forEach((choices = [], optionId = '') => {
      let validChoicesIds = choices.map(choice => choice.id)

      if (validationResultsIds.has(optionId)) {
        const invalidChoicesIds = validationResultsIds.get(optionId) || []

        validChoicesIds = validChoicesIds.filter(choiceId => !invalidChoicesIds.includes(choiceId))
        possibleCombinationChoicesIds.invalid?.set(optionId, invalidChoicesIds)
      }

      possibleCombinationChoicesIds.valid?.set(optionId, validChoicesIds)
    })
  },
  [SET_CONFIG_STRUCTURE_OPTIONS](state, configurationStructureOptions = []) {
    state.configurationStructureOptions = configurationStructureOptions
  },
  [SET_CONFIG_SPECIFICATION_FILTER_OPTIONS](state, configSpecificationFilterOptions = []) {
    state.configSpecificationFilterOptions = configSpecificationFilterOptions
  },
  [SET_CLEAR_CONFIGURATION_OPTIONS_QUERY](state, clearConfigurationOptionsQuery = false) {
    state.clearConfigurationOptionsQuery = clearConfigurationOptionsQuery
  },
  [CHANGED_CONFIGURATION](state, changed = false) {
    state.changedConfiguration = changed
  },
  [REMOVE_CHOICE_FROM_INVALID_OPTIONS](state, { optionId = '', choiceId = '' } = {}) {
    function findKeyById(mapData, target) {
      return mapData.find(data => data?.id === target || data?.choiceId === target) || null
    }

    const foundOption = findKeyById(state.invalidOptions.items, optionId)
    const foundChoice = findKeyById(state.validation.results, choiceId)

    if (foundOption && foundChoice) {
      state.invalidOptions.optionsSize -= 1

      const invalidChoices = state.possibleCombinationChoicesIds.invalid.get(optionId)
      const updatedInvalidChoices = invalidChoices.filter(id => id !== choiceId)

      if (updatedInvalidChoices.length) {
        state.possibleCombinationChoicesIds.invalid.set(optionId, updatedInvalidChoices)
      }
      else {
        state.possibleCombinationChoicesIds.invalid.delete(optionId)
      }

      if (!state.possibleCombinationChoicesIds.invalid.get(optionId)) {
        state.invalidOptions.items = state.invalidOptions.items.filter(item => item.id !== optionId)
        state.invalidOptions.totalSize = state.invalidOptions.items.length
      }
    }
  }
}

export default mutations
