import { BOM_VALIDATIONS_ROUTE, VALIDATIONS_ROUTE } from '@/router/routeNames'

// Children routes
import bom from './bom'
import testCases from './testCases'

const Validations = () => import(/* webpackChunkName: "validations" */ '@/views/Validations.vue')

const routes = [
  {
    path: VALIDATIONS_ROUTE.path,
    name: VALIDATIONS_ROUTE.name,
    component: Validations,
    redirect: {
      name: BOM_VALIDATIONS_ROUTE.name
    },
    meta: {
      title: VALIDATIONS_ROUTE.title,
      isAppHeaderVisible: true,
      isAuthRequired: true,
      authorities: ['TEST_CASES_VALIDATION']
    },
    children: [
      ...bom,
      ...testCases
    ]
  }
]

export default routes
