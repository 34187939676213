export function getSearchChoicesById(id, results) {
  if (results.has(id)) {
    const result = results.get(id)
    return result ? result.choices : []
  }
  return false
}

const getters = {
  getOptions(state) {
    return state.optionsItems.results
  },
  getChoices(state) {
    return state.choicesItems.results
  },
  getSearch(state) {
    return state.searchItems.results
  },
  getSearchChoices(id) {
    return getSearchChoicesById(id, this.getSearch())
  }
}

export default getters
