import { getBOMValidations, getTestCases, getTestCaseValidations } from '@/api'
import {
  SET_BOM_VALIDATIONS,
  SET_TEST_CASES,
  SET_TEST_CASE_VALIDATIONS,
  SET_IN_PROGRESS_BOM_VALIDATIONS, SET_FILTERED_USER_VALIDATIONS, SET_TEST_CASE_PAGE, RESET_TEST_CASE_FILTERS
} from './mutationTypes'

const actions = {
  async getBomValidationsList({ commit }) {
    const bomValidations = await getBOMValidations()

    commit(SET_BOM_VALIDATIONS, bomValidations)
    commit(SET_IN_PROGRESS_BOM_VALIDATIONS, bomValidations)
    commit(SET_FILTERED_USER_VALIDATIONS)

    return bomValidations
  },
  async getTestCasesList({ commit }, {
    page = 0,
    pageSize = 20,
    search = '',
    date = '',
    creator = '',
    isPinned = ''
  } = {}) {
    const queryParams = new URLSearchParams()

    const params = { page, pageSize, search, date, creator, isPinned }

    for (const key in params) {
      const value = params[key]
      if (value !== '' && value !== null) queryParams.append(key, value)
    }

    const testCasesPageable = await getTestCases(queryParams.toString())

    commit(SET_TEST_CASES, testCasesPageable.elements)
    commit(SET_TEST_CASE_PAGE, {
      page: testCasesPageable.pageNumber,
      pageSize: testCasesPageable.pageSize,
      totalPages: testCasesPageable.totalPages,
      totalElements: testCasesPageable.totalElements
    })

    return testCasesPageable
  },
  async getTestCaseValidationsList({ commit }, testCaseId = '') {
    const testCaseValidations = await getTestCaseValidations(testCaseId)

    commit(SET_TEST_CASE_VALIDATIONS, testCaseValidations)

    return testCaseValidations
  },
  async resetTestCaseFiltersAndPagination({ commit }) {
    commit(SET_TEST_CASE_PAGE, { page: 0, pageSize: 20, totalPages: 0 })
    commit(RESET_TEST_CASE_FILTERS, { search: '', date: '', creator: '' })
  }
}

export default actions
