import LocalStorage from 'quasar/src/plugins/LocalStorage.js';
import { HOME_ROUTE } from '@/router/routeNames'

const Home = () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')

const routes = [
  {
    path: HOME_ROUTE.path,
    name: HOME_ROUTE.name,
    component: Home,
    meta: {
      authorities: [],
      isAuthRequired: true
    },
    beforeEnter(to, from, next) {
      const routeName = LocalStorage.getItem(process.env.VUE_APP_STORAGE_KEY_AFTER_SIGN_IN_REDIRECT_TO) || ''

      if (routeName) {
        LocalStorage.remove(process.env.VUE_APP_STORAGE_KEY_AFTER_SIGN_IN_REDIRECT_TO)
        next({ name: routeName })
      }
      else {
        next()
      }
    }
  }
]

export default routes
