import { BOM_VALIDATION_RESULT_TYPES, USER_EXPORT_FILTER } from '@/constants'
import recordAnalytics from '@/utils/analytics/recordAnalytics'
import { ON_DEMAND_EXPORTS_OPENED_ONE } from '@/utils/analytics/constants'
import {
  HANDLE_INITIAL_STATE_SELECTED_EXPORT,
  HANDLE_SELECTED_EXPORT,
  SET_FILTER_TYPE,
  SET_FILTER_USER_EXPORTS,
  SET_FILTERED_USER_EXPORTS,
  SET_SUMMARY_DATA,
  SET_TEST_CASES_FILES,
  SET_USER_EXPORTS, SET_USER_EXPORTS_BY_TYPE, SET_USER_EXPORTS_BY_TYPES,
  SET_VALIDATION,
  SET_VALIDATION_ERROR_AMOUNT,
  SET_VALIDATIONS_EXPORTS,
  UPDATE_SELECTED_VALIDATION_TYPE,
  UPDATE_USER_EXPORT_TYPE,
  UPDATE_USER_EXPORTS_IN_PROGRESS,
  UPDATE_VALIDATION_IN_PROGRESS,
  UPDATE_VALIDATIONS_IN_PROGRESS
} from './mutationTypes'

const filterDataByCriteria = (filters, data) => data.filter(item => filters.every(filter => {
  const { key, value } = filter
  return item.onDemandExportMetadata[key].toString() === value.toString()
}))

const mutations = {
  [SET_USER_EXPORTS](state, exports) {
    state.userExports.results = exports
    state.userExports.size = exports?.length ?? 0
  },
  [SET_USER_EXPORTS_BY_TYPE](state, exports) {
    state.userExports.resultsType = exports
  },
  [SET_USER_EXPORTS_BY_TYPES](state, exports) {
    state.userExports.resultsTypes = exports
  },
  [UPDATE_USER_EXPORTS_IN_PROGRESS](state, value) {
    state.userExports.isInProgress = value
  },
  [HANDLE_SELECTED_EXPORT](state, exports) {
    recordAnalytics(ON_DEMAND_EXPORTS_OPENED_ONE)
    state.userExports.selectedUserExport = exports
    state.userExports.selectedUserExportType = exports?.onDemandExportMetadata?.containsBOM ? 'BOM' : 'TEST_CASES'
  },
  [HANDLE_INITIAL_STATE_SELECTED_EXPORT](state) {
    const isSelectedUserExportValid = state.userExports.filteredResults
      .find(result => state.userExports.selectedUserExport.id === result.id)
    if (!isSelectedUserExportValid) {
      state.userExports.selectedUserExportType = ''
      state.userExports.selectedUserExport = {}
    }
  },
  [UPDATE_USER_EXPORT_TYPE](state, value) {
    state.selectedUserExportType = value
  },
  [SET_VALIDATIONS_EXPORTS](state, value) {
    state.validations.results = value
    state.validations.size = value?.length ?? 0
  },
  [UPDATE_VALIDATIONS_IN_PROGRESS](state, value) {
    state.validations.isInProgress = value
  },
  [UPDATE_VALIDATION_IN_PROGRESS](state, value) {
    state.validation.isInProgress = value
  },
  [SET_VALIDATION](state, value) {
    state.validation.results = value
    state.validation.size = value?.length ?? 0
  },
  [SET_SUMMARY_DATA](state, value) {
    state.validation.summaryData = value
  },
  [SET_VALIDATION_ERROR_AMOUNT](state) {
    const totalErrorTypes = {}

    Object.entries(BOM_VALIDATION_RESULT_TYPES)
      .filter(([, { key }]) => key)
      .forEach(([, { key: value }]) => {
        const errorArray = Object.values(state.validation.results[value])
        totalErrorTypes[value] = {
          totalSize: errorArray.reduce((sum, val) => sum + val.length, 0),
          ...state.validation.results[value]
        }
      })

    state.validation.totalErrorTypes = totalErrorTypes
  },
  [UPDATE_SELECTED_VALIDATION_TYPE](state, { type = '' } = {}) {
    state.validation.selectedValidationType = type
  },
  [SET_TEST_CASES_FILES](state, values) {
    state.testCasesFiles = values
  },
  [SET_FILTER_USER_EXPORTS](state, value) {
    const index = state.userExports.filters.findIndex(filter => filter.key === value.key)

    if (value.key === USER_EXPORT_FILTER.TYPE.key && index !== -1) {
      state.userExports.filters[index].value = value.value
    }
    else if (index !== -1) {
      state.userExports.filters.splice(index, 1)
    }
    else {
      state.userExports.filters.push(value)
    }
  },
  [SET_FILTERED_USER_EXPORTS](state) {
    state.userExports.filteredResults = state.userExports.filters.length
      ? filterDataByCriteria(state.userExports.filters, state.userExports.results)
      : state.userExports.results

    state.userExports.size = state.userExports.filteredResults?.length ?? 0
  },
  [SET_FILTER_TYPE](state, filterType) {
    state.userExports.filterType = filterType
  }
}

export default mutations
