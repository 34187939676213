const state = {
  categoriesItems: {
    results: [],
    size: 0,
    isLoading: false
  },
  highGroups: {
    results: [],
    size: 0,
    isLoading: false
  },
  components: {
    results: [],
    categoryComponents: new Map(),
    size: 0,
    isLoading: false
  },
  componentVariants: {
    results: [],
    componentVariant: new Map(),
    size: 0,
    isLoading: false
  },
  optionsItems: {
    results: [],
    categoryOption: new Map(),
    size: 0,
    isLoading: false
  },
  choicesItems: {
    results: [],
    optionChoices: new Map(),
    size: 0,
    isLoading: false
  },
  searchItems: {
    results: new Map()
  }
}

export default state
