import cloneDeep from 'lodash.clonedeep'

import { SEARCH_ELEMENT_TYPES, SEARCH_RESULT_TYPES, ELEMENT_STATES, RESULTS_FILTER } from '@/constants'
import {
  CLEAR_SEARCH_RESULTS,
  CLEAR_SELECTED_ELEMENTS,
  SET_ELEMENTS,
  SET_COMPONENTS_FOR_LE,
  SET_ELEMENTS_LOADING_STATE,
  SET_SEARCH_PERFORMED_STATE,
  TOGGLE_ELEMENT_STATE,
  TOGGLE_SUB_ELEMENT_STATE,
  UPDATE_SELECTED_ELEMENT_TYPE,
  UPDATE_SELECTED_RESULT_TYPE,
  UPDATE_RESULTS_FILTER,
  UPDATE_RESULTS_FILTER_TABLE,
  UPDATE_SELECTED_ELEMENTS,
  REPLACE_SELECTED_ELEMENTS,
  SET_COMPONENT_FOR_LE,
  RESET_COMPONENT_FOR_LE,
  TOGGLE_EDIT_SOLUTION_ELEMENTS_STATE,
  UPDATE_RESULTS_FILTER_EXCEL,
  SET_ALL_ELEMENTS,
  SET_ALL_SUB_ELEMENTS,
  SET_RESULTS_FOR_LATER,
  SET_COMBINATIONS_LOADING,
  UPDATE_PREVIEW_ELEMENTS_AFTER_PM_CHANGED,
  UPDATE_ELEMENTS
} from './mutationTypes'

function getTargetElements(state, previewElements, previewElementsIds, selectedElements, selectedElementsIds) {
  if (ELEMENT_STATES.IN_PREVIEW === state) {
    return { elements: previewElements, elementsIds: previewElementsIds }
  }
  if (ELEMENT_STATES.SELECTED === state) {
    return { elements: selectedElements, elementsIds: selectedElementsIds }
  }
  return { elements: {}, elementsIds: {} }
}

function removeSubElementFromTarget(
  targetItems,
  targetItemsIds,
  subElement,
  subElementsType,
  parentId,
  targetElements,
  elementsType
) {
  const existingSubItemsIds = targetItemsIds.get(subElement[parentId]) || []
  const targetItemIdx = [...targetItemsIds.keys()].indexOf(subElement[parentId])
  const targetSubItemIdx = existingSubItemsIds.findIndex(subItemId => subItemId === subElement.id)

  if (targetSubItemIdx > -1) {
    if (existingSubItemsIds.length - 1) {
      const targetItem = targetItems[targetItemIdx] || {}
      targetItem[subElementsType] = targetItem[subElementsType]?.filter(subItem => subItem.id !== subElement.id)
      targetItemsIds.set(subElement[parentId], targetItem[subElementsType]?.map(subItem => subItem.id) || [])
    }
    else {
      targetElements[elementsType] = targetItems.filter(item => item.id !== subElement[parentId])
      targetItemsIds.delete(subElement[parentId])
    }
  }
}

function addSubElementToTarget(targetItems, targetItemsIds, subElement, subElementsType, parentId) {
  const previewItemIdx = [...targetItemsIds.keys()].indexOf(subElement[parentId])
  const existingSubItemsIds = targetItemsIds.get(subElement[parentId]) || []
  existingSubItemsIds.push(subElement.id)
  targetItems[previewItemIdx][subElementsType]?.push({ ...subElement })
  targetItemsIds.set(subElement[parentId], existingSubItemsIds)
}

function setSubElement(
  {
    isChosen,
    targetItems,
    targetItemsIds,
    subElement,
    subElementsType,
    parentId,
    targetElements,
    elementsType,
    elementSingularTypeName
  }
) {
  if (isChosen) {
    removeSubElementFromTarget(
      targetItems,
      targetItemsIds,
      subElement,
      subElementsType,
      parentId,
      targetElements,
      elementsType
    )
  }
  else if (targetItemsIds.has(subElement[parentId])) {
    const existingSubItemsIds = targetItemsIds.get(subElement[parentId]) || []
    if (!existingSubItemsIds.includes(subElement.id)) {
      addSubElementToTarget(targetItems, targetItemsIds, subElement, subElementsType, parentId)
    }
  }
  else {
    const element = {
      id: subElement[parentId],
      id2: subElement[`${ elementSingularTypeName }Id2`],
      oid: subElement[`${ elementSingularTypeName }Oid`],
      name: subElement[`${ elementSingularTypeName }Name`],
      [subElementsType]: [{ ...subElement }]
    }

    targetItemsIds.set(element.id, [subElement.id])
    targetItems.push(element)
  }
}

function setPreviewElements(previewElements, elements) {
  return previewElements.map(previewElement => {
    const element = elements?.find(el => el.id === previewElement.id)
    return element || previewElement
  })
}

function updatePreviewElementsIds(items, subItemsType) {
  const map = new Map()
  items.forEach(item => map.set(item.id, item[subItemsType].map(subItem => subItem.id)))
  return map
}

function withDetectedChanges(el, data) {
  return data.find(item => item.id2 === el.id2) || el
}

const mutations = {
  [CLEAR_SEARCH_RESULTS]({ elements = {}, previewElements = {}, previewElementsIds = {} } = {}) {
    Object.values(elements).forEach(elementType => {
      Object.values(elementType).forEach(resultType => {
        resultType.items.length = 0
        resultType.totalSize = 0
      })
    })

    Object.values(previewElements).forEach(previewElementType => {
      previewElementType.length = 0
    })

    Object.values(previewElementsIds).forEach(previewElementIds => {
      previewElementIds.clear()
    })
  },
  [UPDATE_PREVIEW_ELEMENTS_AFTER_PM_CHANGED]({ elements = {}, previewElements = {} } = {}) {
    const { options, components } = elements[SEARCH_ELEMENT_TYPES.STRUCTURE] || {}
    previewElements.options = setPreviewElements(previewElements.options, options.items)
    previewElements.components = setPreviewElements(previewElements.components, components.items)
  },
  [CLEAR_SELECTED_ELEMENTS]({ selectedElements = {}, selectedElementsIds = {} } = {}, elementsType = '') {
    if (elementsType in selectedElements) {
      selectedElements[elementsType].length = 0
      selectedElementsIds[elementsType]?.clear()
    }
  },
  [SET_ELEMENTS](
    { elements = {} } = {},
    { elementType = SEARCH_ELEMENT_TYPES.STRUCTURE, resultType = '', items = [], totalSize = 0 } = {}
  ) {
    const targetElementType = elements[elementType] || {}

    if (resultType in targetElementType) {
      const targetResultType = targetElementType[resultType] || {}

      targetResultType.items = items
      targetResultType.totalSize = totalSize
    }
  },
  [UPDATE_ELEMENTS](
    { elements = {}, previewElements = {}, previewElementsIds = {} } = {},
    { elementType = SEARCH_ELEMENT_TYPES.STRUCTURE, subElementsType = '', resultType = '', newElements = [] } = {}
  ) {
    const typedElements = elements[elementType][resultType]
    const { items: typedStructuredElements } = typedElements

    elements[elementType][resultType].items = typedStructuredElements.map(el => withDetectedChanges(el, newElements))

    previewElements[resultType] = previewElements[resultType].map(el => withDetectedChanges(el, newElements))

    previewElementsIds[resultType] = updatePreviewElementsIds(previewElements[resultType], subElementsType)
  },
  [SET_COMPONENTS_FOR_LE]({ selectedElements } = {},
    { selectedForLE = false, elementsType = SEARCH_RESULT_TYPES.COMPONENTS } = {}) {
    selectedElements[elementsType] = selectedElements[elementsType].map(
      component => ({ ...component, selectedForLE })
    )
  },
  [SET_COMPONENT_FOR_LE](
    { selectedElements, resetSelectedElementsIds } = {},
    { selectedElementId = '', elementsType = SEARCH_RESULT_TYPES.COMPONENTS } = {}
  ) {
    const selectedElement = selectedElements[elementsType].find(element => element.id === selectedElementId)
    if (selectedElement) {
      if (!resetSelectedElementsIds.has(selectedElement.id)) {
        resetSelectedElementsIds.set(selectedElement.id, selectedElement.selectedForLE)
      }
      selectedElement.selectedForLE = !selectedElement.selectedForLE
    }
  },
  [RESET_COMPONENT_FOR_LE](
    { selectedElements, resetSelectedElementsIds } = {},
    { elementsType = SEARCH_RESULT_TYPES.COMPONENTS } = {}
  ) {
    resetSelectedElementsIds.forEach((value, key) => {
      const selectedElement = selectedElements[elementsType].find(element => element.id === key)
      if (selectedElement) selectedElement.selectedForLE = value
      resetSelectedElementsIds.delete(key)
    })
  },
  [SET_ELEMENTS_LOADING_STATE](state, areElementLoading = false) {
    state.areElementsLoading = areElementLoading
  },
  [SET_SEARCH_PERFORMED_STATE](state, isSearchPerformed = false) {
    state.isSearchPerformed = isSearchPerformed
  },
  [TOGGLE_ELEMENT_STATE](
    { previewElements = {}, previewElementsIds = {}, selectedElements = {}, selectedElementsIds = {} },
    { element = {}, elementsType = '', elementState = '', subElementsType = '', isChosen = false } = {}
  ) {
    let targetElements = {}
    let targetElementsIds = {}

    if (ELEMENT_STATES.IN_PREVIEW === elementState || ELEMENT_STATES.SELECTED === elementState) {
      const { elements, elementsIds } = getTargetElements(
        elementState,
        previewElements,
        previewElementsIds,
        selectedElements,
        selectedElementsIds
      )
      targetElements = elements
      targetElementsIds = elementsIds
    }

    element.selectedForLE = false

    if (isChosen) {
      targetElementsIds[elementsType]?.delete(element.id)
      targetElements[elementsType] = targetElements[elementsType]?.filter(item => item.id !== element.id)
    }
    else {
      const subElementsIds = element[subElementsType]?.map(subElement => subElement.id) || []

      targetElementsIds[elementsType]?.set(element.id, subElementsIds)
      targetElements[elementsType]?.push(cloneDeep(element))
    }
  },
  [TOGGLE_SUB_ELEMENT_STATE](
    { previewElements = {}, previewElementsIds = {}, selectedElements = {}, selectedElementsIds = {} } = {},
    { subElement = {}, subElementsType = '', subElementState = '', elementsType = '', isChosen = false } = {}
  ) {
    let targetElements = {}
    let targetElementsIds = {}

    if (ELEMENT_STATES.IN_PREVIEW === subElementState || ELEMENT_STATES.SELECTED === subElementState) {
      const { elements, elementsIds } = getTargetElements(
        subElementState,
        previewElements,
        previewElementsIds,
        selectedElements,
        selectedElementsIds
      )
      targetElements = elements
      targetElementsIds = elementsIds
    }

    const targetItems = targetElements[elementsType] || []
    const targetItemsIds = targetElementsIds[elementsType] || new Map()
    const elementSingularTypeName = elementsType.slice(0, -1)
    const parentId = `${ elementSingularTypeName }Id`

    setSubElement(
      {
        isChosen,
        targetItems,
        targetItemsIds,
        subElement,
        subElementsType,
        parentId,
        targetElements,
        elementsType,
        elementSingularTypeName
      }
    )
  },
  [SET_ALL_SUB_ELEMENTS](
    {
      elements,
      selectedElements,
      selectedElementsIds,
      previewElements,
      previewElementsIds
    } = {}, subElementsType = '', isChosen = false
  ) {
    const parentElementType = subElementsType === SEARCH_RESULT_TYPES.CHOICES
      ? SEARCH_RESULT_TYPES.OPTIONS : SEARCH_RESULT_TYPES.COMPONENTS

    const { items: elementsToBeAdd } = elements[SEARCH_ELEMENT_TYPES.STRUCTURE][subElementsType]

    const targetParentSelectedItems = selectedElements[parentElementType] || []
    const targetParentSelectedItemsIds = selectedElementsIds[parentElementType] || new Map()

    const targetParentPreviewItems = previewElements[parentElementType] || []
    const targetParentPreviewItemsIds = previewElementsIds[parentElementType] || new Map()

    const elementSingularTypeName = parentElementType.slice(0, -1)
    const parentId = `${ elementSingularTypeName }Id`

    const subElementsParams = {
      isChosen,
      subElementsType,
      parentId,
      elementsType: parentElementType,
      elementSingularTypeName
    }

    const setSubElementByTarget = (subElement, targetItems, targetItemsIds, targetElements = '') => {
      setSubElement(
        { ...subElementsParams, subElement, targetItems, targetItemsIds, targetElements }
      )
    }

    elementsToBeAdd.forEach(subElement => {
      setSubElementByTarget(subElement, targetParentPreviewItems, targetParentPreviewItemsIds)
      setSubElementByTarget(subElement, targetParentSelectedItems, targetParentSelectedItemsIds)
    })
  },
  [SET_ALL_ELEMENTS]({
    elements,
    selectedElements,
    selectedElementsIds,
    previewElements,
    previewElementsIds
  } = {}, elementType = '') {
    const { items: elementsToBeAdd } = elements[SEARCH_ELEMENT_TYPES.STRUCTURE][elementType]

    const subElementsType = elementType
    === SEARCH_RESULT_TYPES.OPTIONS ? SEARCH_RESULT_TYPES.CHOICES : SEARCH_RESULT_TYPES.COMPONENT_VARIANTS

    const addElements = (targetElements, element, elType) => {
      const elemenetAlreadyExists = targetElements[elType].some(el => el.id === element.id)
      if (elemenetAlreadyExists) {
        const targetElementsFiltered = targetElements[elType].filter(item => item.id !== element.id)
        targetElements[elType] = targetElementsFiltered
      }
      targetElements[elType]?.push(cloneDeep(element))
    }

    elementsToBeAdd.forEach(element => {
      const subElementsIds = element[subElementsType]?.map(subElement => subElement.id) || []
      selectedElementsIds[elementType]?.set(element.id, subElementsIds)
      previewElementsIds[elementType]?.set(element.id, subElementsIds)
      addElements(selectedElements, element, elementType)
      addElements(previewElements, element, elementType)
    })
  },
  [UPDATE_SELECTED_ELEMENT_TYPE](state, elementType = '') {
    if (!elementType || Object.values(SEARCH_ELEMENT_TYPES).includes(elementType)) {
      state.selectedElementType = elementType
    }
  },
  [UPDATE_SELECTED_RESULT_TYPE](state, resultType = SEARCH_RESULT_TYPES.OPTIONS) {
    if (Object.values(SEARCH_RESULT_TYPES).includes(resultType)) {
      state.selectedResultType = resultType
    }
  },
  [UPDATE_RESULTS_FILTER](state, filterType = '') {
    if (Object.values(RESULTS_FILTER).includes(filterType)) {
      state.resultsFilterSearch[filterType] = !state.resultsFilterSearch[filterType]
    }
  },
  [UPDATE_RESULTS_FILTER_TABLE]({ resultsFilter = {} } = {}, { filterType = '', newVal }) {
    if (filterType && Object.values(RESULTS_FILTER).includes(filterType)) {
      resultsFilter[filterType] = newVal
    }
  },
  [UPDATE_RESULTS_FILTER_EXCEL]({ resultsFilterExcel = {} } = {}, { filterType = '', newVal }) {
    if (filterType && Object.values(RESULTS_FILTER).includes(filterType)) {
      resultsFilterExcel[filterType] = newVal
    }
  },
  [UPDATE_SELECTED_ELEMENTS](
    { selectedElements = {}, selectedElementsIds = {} } = {},
    { elementsType = '', subElementsType = '', elements = [] } = {}
  ) {
    const targetElements = selectedElements[elementsType]
    const targetElementsIds = selectedElementsIds[elementsType]
    const elementsLength = elements.length

    if (!targetElements || !targetElementsIds) return

    for (let i = 0; i < elementsLength; i++) {
      const element = elements[i]

      if (targetElementsIds.has(element.id)) {
        const targetElementIdx = [...targetElementsIds.keys()].indexOf(element.id)

        if (targetElementIdx > -1 && subElementsType) {
          targetElements[targetElementIdx][subElementsType] = element[subElementsType]
          targetElementsIds.set(element.id, element[subElementsType]?.map(item => item.id) || [])
        }
      }
      else {
        targetElements.push(element)
        targetElementsIds.set(element.id, element[subElementsType]?.map(subElement => subElement.id) || [])
      }
    }
  },
  [REPLACE_SELECTED_ELEMENTS](
    { selectedElements = {}, selectedElementsIds = {} } = {},
    { elementsType = '', subElementsType = '', elements = [] } = {}
  ) {
    if (!selectedElements[elementsType] || !subElementsType) return

    selectedElements[elementsType] = elements
    const selectedIdsMap = new Map()
    elements.forEach(
      element => selectedIdsMap.set(element.id, element[subElementsType]?.map(subElement => subElement.id))
    )
    selectedElementsIds[elementsType] = selectedIdsMap
  },
  [TOGGLE_EDIT_SOLUTION_ELEMENTS_STATE](state) {
    state.showEditSolutionElements = !state.showEditSolutionElements
  },
  [SET_RESULTS_FOR_LATER](state, { showResultsLater = false } = {}) {
    state.showResultsLater = showResultsLater
  },
  [SET_COMBINATIONS_LOADING](state, { areCombinationsLoadingValue = false } = {}) {
    state.areCombinationsLoading = areCombinationsLoadingValue
  }
}

export default mutations
