import emitFlowState from '@/utils/custom-events/customEventsEmit'
import { createRouter, createWebHistory } from 'vue-router'
import { updatePageTitle } from './utils'
import { validateUserTokenAndAuthorities } from './guards'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Router navigation hooks
router.beforeEach((to, from, next) => {
// router.beforeEach(to => {
  updatePageTitle(to)
  if (to.name) emitFlowState(to.name, 'start')
  validateUserTokenAndAuthorities(to, from, next)
})

export default router
