import actions from '@/store/modules/whatsNew/actions'
import state from './state'
import mutations from './mutations'

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
