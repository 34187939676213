import { SCHEDULES_ROUTE, SCHEDULES_DETAILS_ROUTE, SCHEDULES_SEARCH_ROUTE } from '@/router/routeNames'

const Schedules = () => import(
  /* webpackChunkName: "schedules-page" */ '@/views/Schedules.vue'
)

const SchedulesDetails = () => import(
  /* webpackChunkName: "schedules-details-page" */ '@/views/SchedulesDetails.vue'
)

const SchedulesSearchModal = () => import(
  /* webpackChunkName: "schedules-details-search" */ '@/components/schedules/search/modal/SchedulesSearchModal.vue'
)

const routes = [
  {
    path: SCHEDULES_ROUTE.path,
    name: SCHEDULES_ROUTE.name,
    component: Schedules,
    meta: {
      appFeatureSubRoute: SCHEDULES_ROUTE,
      title: SCHEDULES_ROUTE.title
    }
  },
  {
    path: SCHEDULES_DETAILS_ROUTE.path,
    name: SCHEDULES_DETAILS_ROUTE.name,
    component: SchedulesDetails,
    meta: {
      appFeatureSubRoute: SCHEDULES_DETAILS_ROUTE,
      title: SCHEDULES_DETAILS_ROUTE.title
    },
    children: [
      {
        path: SCHEDULES_SEARCH_ROUTE.path,
        name: SCHEDULES_SEARCH_ROUTE.name,
        component: SchedulesSearchModal,
        meta: {
          appFeatureSubRoute: SCHEDULES_SEARCH_ROUTE,
          title: SCHEDULES_SEARCH_ROUTE.title
        }
      }
    ]
  }
]

export default routes
