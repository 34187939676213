import { PAC_ROUTE, PAC_CONFIGURATION_ROUTE, PAC_CONFIGURATIONS_ROUTE } from '@/router/routeNames'

const Pac = () => import(/* webpackChunkName: "pac" */ '@/views/Pac.vue')

// Configurations
const ConfigurationsTable = () => import(
  /* webpackChunkName: "pac" */ '@/components/pac/configurations/ConfigurationsTable.vue'
)
const ConfigurationsHeader = () => import(
  /* webpackChunkName: "pac" */ '@/components/pac/configurations/ConfigurationsHeader.vue'
)

// Configuration
const Configuration = () => import(/* webpackChunkName: "pac" */ '@/components/pac/configuration/Configuration.vue')
const ConfigurationHeader = () => import(
  /* webpackChunkName: "pac" */ '@/components/pac/configuration/header/ConfigurationHeader.vue'
)

const routes = [
  {
    path: PAC_ROUTE.path,
    name: PAC_ROUTE.name,
    component: Pac,
    redirect: {
      name: PAC_CONFIGURATIONS_ROUTE.name
    },
    meta: {
      authorities: ['PAC_ACCESS'],
      isAppHeaderVisible: true,
      isAuthRequired: true,
      title: PAC_ROUTE.title
    },
    children: [
      {
        path: PAC_CONFIGURATIONS_ROUTE.path,
        name: PAC_CONFIGURATIONS_ROUTE.name,
        components: {
          default: ConfigurationsTable,
          header: ConfigurationsHeader
        }
      },
      {
        path: PAC_CONFIGURATION_ROUTE.path,
        name: PAC_CONFIGURATION_ROUTE.name,
        components: {
          default: Configuration,
          header: ConfigurationHeader
        },
        props: {
          default: route => ({ configurationId: route.params.id })
        }
      }
    ]
  }
]

export default routes
