const state = {
  configurationsSearchString: '',
  currentConfiguration: {},
  lockedChoicesIds: new Map(),
  selectedChoices: new Map(),
  possibleCombinationChoicesIds: {
    valid: new Map(),
    invalid: new Map()
  },
  options: {
    areLoading: false,
    items: [],
    totalSize: 0
  },
  invalidOptions: {
    areLoading: false,
    items: [],
    totalSize: 0,
    optionsSize: 0
  },
  validation: {
    results: [],
    isInProgress: false,
    showBadge: false
  },
  isValidValidation: false,
  errorCode: null,
  initialState: true,
  configurationStructureOptions: new Map(),
  configSpecificationFilterOptions: new Map(),
  clearConfigurationOptionsQuery: false,
  changedConfiguration: false
}

export default state
