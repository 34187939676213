import LocalStorage from 'quasar/src/plugins/LocalStorage.js';
import { PRODUCT_MODEL_ROUTE, PRODUCT_MODELS_ROUTE } from '@/router/routeNames'

const ProductModels = () => import(/* webpackChunkName: "product-models" */ '@/views/ProductModels.vue')
const ProductModelDetails = () => import(
  /* webpackChunkName: "product-models" */
  '@/components/product-model/ProductModelDetails.vue'
)

const routes = [
  {
    path: PRODUCT_MODELS_ROUTE.path,
    name: PRODUCT_MODELS_ROUTE.name,
    component: ProductModels,
    meta: {
      appFeatureSubRoute: PRODUCT_MODELS_ROUTE,
      title: PRODUCT_MODELS_ROUTE.title,
      authorities: ['LIST_PMS']
    },
    children: [
      {
        path: PRODUCT_MODEL_ROUTE.path,
        name: PRODUCT_MODEL_ROUTE.name,
        component: ProductModelDetails
      }
    ],
    beforeEnter(to, from, next) {
      const { encodedBusinessName } = LocalStorage.getItem(process.env.VUE_APP_STORAGE_KEY_PRODUCT_MODEL) || {}

      if (encodedBusinessName && to.name === PRODUCT_MODELS_ROUTE.name) {
        next({ name: PRODUCT_MODEL_ROUTE.name, params: { encodedBusinessName } })
      }
      else {
        next()
      }
    }
  }
]

export default routes
