import LocalStorage from 'quasar/src/plugins/LocalStorage.js';
import { PRODUCT_MODELS_ROUTE, SEARCH_ROUTE, VARIANT_MANAGEMENT_ROUTE } from '@/router/routeNames'

import VariantManagement from '@/views/VariantManagement.vue'

// Children routes
import savedTables from '@/router/routes/saved-tables'
import schedules from '@/router/routes/schedules'
import productModels from './productModels'
import search from './search'
import browsePorfolio from './browsePortfolio'

const routes = [
  {
    path: VARIANT_MANAGEMENT_ROUTE.path,
    name: VARIANT_MANAGEMENT_ROUTE.name,
    component: VariantManagement,
    meta: {
      title: VARIANT_MANAGEMENT_ROUTE.title,
      isAppHeaderVisible: true,
      isAuthRequired: true,
      authorities: ['LIST_PMS', 'SEARCH_PM_CONTENT']
    },
    redirect() {
      const { id: productModelId } = LocalStorage.getItem(process.env.VUE_APP_STORAGE_KEY_PRODUCT_MODEL) || {}
      const { name } = productModelId ? SEARCH_ROUTE : PRODUCT_MODELS_ROUTE

      return { name }
    },
    children: [
      ...productModels,
      ...search,
      ...browsePorfolio,
      ...savedTables,
      ...schedules
    ]
  }
]

export default routes
