import { BOM_VALIDATION_RESULTS_ROUTE, BOM_VALIDATIONS_ROUTE } from '@/router/routeNames'

const BomValidationsTable = () => import(
  /* webpackChunkName: "bom-validations" */ '@/components/validations/bom/BomValidationsTable.vue'
)
const BomValidationResults = () => import(
  /* webpackChunkName: "bom-validation-results" */
  '@/components/validations/bom/validation-results/BomValidationResults.vue'
)

const routes = [
  {
    path: BOM_VALIDATIONS_ROUTE.path,
    name: BOM_VALIDATIONS_ROUTE.name,
    component: BomValidationsTable,
    meta: {
      appFeatureSubRoute: BOM_VALIDATIONS_ROUTE,
      title: BOM_VALIDATIONS_ROUTE.title
    }
  },
  {
    path: BOM_VALIDATION_RESULTS_ROUTE.path,
    name: BOM_VALIDATION_RESULTS_ROUTE.name,
    component: BomValidationResults,
    meta: {
      appFeatureSubRoute: BOM_VALIDATIONS_ROUTE,
      title: BOM_VALIDATION_RESULTS_ROUTE.title,
      breadcrumbs: [
        { label: BOM_VALIDATIONS_ROUTE.title, to: { name: BOM_VALIDATIONS_ROUTE.name } },
        { label: 'BOM ID: :id' }
      ]
    }
  }
]

export default routes
