export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS'
export const CLEAR_SELECTED_ELEMENTS = 'CLEAR_SELECTED_ELEMENTS'
export const SET_ELEMENTS = 'SET_ELEMENTS'
export const SET_COMPONENTS_FOR_LE = 'SET_COMPONENTS_FOR_LE'
export const SET_COMPONENT_FOR_LE = 'SET_COMPONENT_FOR_LE'
export const RESET_COMPONENT_FOR_LE = 'RESET_COMPONENT_FOR_LE'
export const SET_ELEMENTS_LOADING_STATE = 'SET_ELEMENTS_LOADING_STATE'
export const SET_SEARCH_PERFORMED_STATE = 'SET_SEARCH_PERFORMED_STATE'
export const TOGGLE_ELEMENT_STATE = 'TOGGLE_ELEMENT_STATE'
export const TOGGLE_SUB_ELEMENT_STATE = 'TOGGLE_SUB_ELEMENT_STATE'
export const UPDATE_ELEMENTS = 'UPDATE_ELEMENTS'
export const UPDATE_SELECTED_ELEMENT_TYPE = 'UPDATE_SELECTED_ELEMENT_TYPE'
export const UPDATE_SELECTED_RESULT_TYPE = 'UPDATE_SELECTED_RESULT_TYPE'
export const UPDATE_RESULTS_FILTER = 'UPDATE_RESULTS_FILTER'
export const UPDATE_RESULTS_FILTER_TABLE = 'UPDATE_RESULTS_FILTER_TABLE'
export const UPDATE_RESULTS_FILTER_EXCEL = 'UPDATE_RESULTS_FILTER_EXCEL'
export const UPDATE_SELECTED_ELEMENTS = 'UPDATE_SELECTED_ELEMENTS'
export const REPLACE_SELECTED_ELEMENTS = 'REPLACE_SELECTED_ELEMENTS'
export const TOGGLE_EDIT_SOLUTION_ELEMENTS_STATE = 'TOGGLE_EDIT_SOLUTION_ELEMENTS_STATE'
export const SET_ALL_ELEMENTS = 'SET_ALL_ELEMENTS'
export const SET_ALL_SUB_ELEMENTS = 'SET_ALL_SUB_ELEMENTS'
export const SET_RESULTS_FOR_LATER = 'SET_RESULTS_FOR_LATER'
export const SET_COMBINATIONS_LOADING = 'SET_COMBINATIONS_LOADING'
export const UPDATE_PREVIEW_ELEMENTS_AFTER_PM_CHANGED = 'UPDATE_PREVIEW_ELEMENTS_AFTER_PM_CHANGED'
