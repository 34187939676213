const queryProductModelsKeys = ['id', 'encodedBusinessName']

const getters = {
  selectedProductModel(state) {
    return state.productModels.find(
      productModel => productModel.encodedBusinessName === state.selectedProductModelEncodedBusinessName
    ) || {}
  },
  hasSelectedSearchProductModel(state) {
    return state?.selectedSearchProductModel && Object.keys(state.selectedSearchProductModel).length > 0
  },
  selectedSearchProductModel(state) {
    const hasSelectedSearchPm = getters.hasSelectedSearchProductModel()
    return hasSelectedSearchPm ? state.selectedProductModel : null
  },
  queryProductModels(state) {
    return queryProductModelsKeys.reduce((result, key) => {
      result[key] = state.productModels.map(productModel => productModel[key]).join(',')
      return result
    }, {})
  }
}

export default getters
