const state = {
  productModels: [],
  areProductModelsLoading: false,
  selectedProductModelEncodedBusinessName: null,
  searchProductModels: [],
  selectedSearchProductModel: {},
  productModelsAutoBom: {
    loading: true,
    counts: { setupCount: 0, completedCount: 0 },
    lastUpdate: ''
  }
}

export default state
