// User Exports
export const SET_USER_EXPORTS = 'SET_USER_EXPORTS'
export const SET_USER_EXPORTS_BY_TYPE = 'SET_USER_EXPORTS_BY_TYPE'
export const SET_USER_EXPORTS_BY_TYPES = 'SET_USER_EXPORTS_BY_TYPES'
export const HANDLE_SELECTED_EXPORT = 'HANDLE_SELECTED_EXPORT'
export const HANDLE_INITIAL_STATE_SELECTED_EXPORT = 'HANDLE_INITIAL_STATE_SELECTED_EXPORT'
export const UPDATE_USER_EXPORTS_IN_PROGRESS = 'UPDATE_IN_PROGRESS'
export const UPDATE_USER_EXPORT_TYPE = 'UPDATE_USER_EXPORT_TYPE'
export const SET_FILTER_USER_EXPORTS = 'SET_FILTER_USER_EXPORTS'
export const SET_FILTERED_USER_EXPORTS = 'SET_FILTERED_USER_EXPORTS'
export const SET_FILTER_TYPE = 'SET_FILTER_TYPE'

// Validations
export const SET_VALIDATIONS_EXPORTS = 'SET_VALIDATIONS_EXPORTS'
export const UPDATE_VALIDATIONS_IN_PROGRESS = 'UPDATE_VALIDATIONS_IN_PROGRESS'

// Validation
export const SET_VALIDATION = 'SET_VALIDATIONS'
export const SET_VALIDATION_ERROR_AMOUNT = 'SET_VALIDATION_ERROR_AMOUNT'
export const UPDATE_VALIDATION_IN_PROGRESS = 'UPDATE_VALIDATIONS_IN_PROGRESS'
export const UPDATE_SELECTED_VALIDATION_TYPE = 'UPDATE_SELECTED_VALIDATION_TYPE'
export const SET_SUMMARY_DATA = 'SET_SUMMARY_DATA'

// TestCases Files
export const SET_TEST_CASES_FILES = 'SET_TEST_CASES_FILES'
