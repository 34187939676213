import { USER_FEEDBACK_ROUTE } from '@/router/routeNames'

const UserFeedback = () => import('@/views/UserFeedback.vue')

export const routes = [
  {
    path: USER_FEEDBACK_ROUTE.path,
    name: USER_FEEDBACK_ROUTE.name,
    component: UserFeedback,
    meta: {
      title: USER_FEEDBACK_ROUTE.title,
      authorities: [],
      isAppHeaderVisible: true,
      isAuthRequired: true
    }
  }
]

export default routes
