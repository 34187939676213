const getters = {
  aliasesAsRequestParams({ appliedAliasesIds = [] } = {}) {
    const aliases = {}
    const aliasesLength = appliedAliasesIds.length

    for (let i = 0; i < aliasesLength; i++) {
      aliases[`aliases[${ i }][id]`] = appliedAliasesIds[i]
    }

    return aliases
  },
  filtersAsRequestParams({ appliedFilters = [] } = {}) {
    const filters = {}
    const appliedFiltersLength = appliedFilters.length

    for (let i = 0; i < appliedFiltersLength; i++) {
      const { field = '', value = '' } = appliedFilters[i]

      if (field && value) {
        filters[`filters[${ i }][field]`] = field
        filters[`filters[${ i }][type]`] = 'like'
        filters[`filters[${ i }][value]`] = value
      }
    }

    return filters
  },
  sortersAsRequestParams({ appliedSorters = [] } = {}) {
    const sorters = {}
    const appliedSortersLength = appliedSorters.length

    for (let i = 0; i < appliedSortersLength; i++) {
      const { column = '', dir = '' } = appliedSorters[i]

      if (column && dir) {
        sorters[`sorters[${ i }][field]`] = column
        sorters[`sorters[${ i }][dir]`] = dir
      }
    }

    return sorters
  }
}

export default getters
