import {
  getConfiguration,
  getConfigurationCombinations,
  searchOptions,
  updateConfiguration,
  validateConfiguration,
  validateConfigurationV2,
  getValidateConfiguration,
  getWindchillConfigSpecs,
  getConfigurationCombinationsData
} from '@/api'

import {
  REMOVE_CHOICE_FROM_VALIDATION_RESULTS,
  SET_ARE_OPTIONS_LOADING,
  SET_CURRENT_CONFIGURATION,
  SET_LOCKED_CHOICES_IDS,
  SET_OPTIONS,
  SET_POSSIBLE_COMBINATION_CHOICES_IDS,
  SET_SELECTED_CHOICES,
  SET_VALIDATION,
  TOGGLE_CHOICE_LOCK,
  TOGGLE_CHOICE_SELECTION,
  UPDATE_POSSIBLE_COMBINATIONS_AFTER_VALIDATION,
  SET_CONFIG_STRUCTURE_OPTIONS,
  SET_CONFIG_SPECIFICATION_FILTER_OPTIONS,
  SET_INVALID_OPTIONS,
  REMOVE_CHOICE_FROM_INVALID_OPTIONS,
  CHANGED_CONFIGURATION
} from '@/store/modules/pac/mutationTypes'
import { ASYNC_REQUEST_STATUS, PM_TYPE } from '@/constants'
import { poll } from '@/api/polling'

const actions = {
  async getConfigurationOptions({ commit, state }, query = '') {
    const { encodedBusinessName = '' } = state.currentConfiguration?.productModel || {}

    commit(SET_ARE_OPTIONS_LOADING, true)

    try {
      const { elements: options } = await searchOptions({
        query,
        pmIdentifier: encodedBusinessName,
        pmType: PM_TYPE.PRODUCT_MODEL,
        numberOfElements: 0
      })

      commit(SET_OPTIONS, Object.freeze(options))

      return options
    }
    finally {
      commit(SET_ARE_OPTIONS_LOADING, false)
    }
  },
  async getPossibleCombinationsAsync({ commit, getters, state }, relevantDomains = []) {
    const {
      planningPeriodRange = {},
      ignoreList: rulesToIgnore
    } = state.currentConfiguration
    const { simplifiedSelectedChoices: selectedChoices, configurationReference: reference } = getters

    const params = {
      planningPeriodRange,
      reference,
      relevantDomains,
      rulesToIgnore,
      selectedChoices
    }

    const { executePoll } = poll()

    const { combinations } = await executePoll({
      correlationIdEndpoint: {
        getCorrelationId: getConfigurationCombinations,
        params
      },
      timeout: 2000,
      asyncEndpoint: getConfigurationCombinationsData
    })

    commit(SET_POSSIBLE_COMBINATION_CHOICES_IDS, combinations)

    return combinations
  },
  async loadConfiguration({ commit }, id = '') {
    const configuration = await getConfiguration(id)

    commit(SET_CURRENT_CONFIGURATION, configuration)
    commit(SET_LOCKED_CHOICES_IDS, configuration.selectedChoices)
    commit(SET_SELECTED_CHOICES, configuration.selectedChoices?.map(({ choice }) => choice) || [])

    return configuration
  },
  async saveConfiguration({ commit }, configuration = {}) {
    const { modifiedDate } = await updateConfiguration(configuration)

    commit(SET_CURRENT_CONFIGURATION, { ...configuration, modifiedDate })

    return { ...configuration, modifiedDate }
  },
  async validateCurrentConfiguration({ commit, state, getters }) {
    const {
      planningPeriodRange,
      ignoreList: ruleIdsToIgnore
    } = state.currentConfiguration || {}
    const { encodedBusinessName = '' } = state.currentConfiguration?.productModel || {}
    const { simplifiedSelectedChoices: selectedChoices } = getters

    commit(SET_VALIDATION, {
      isInProgress: true,
      isValidValidation: state.isValidValidation ?? false,
      initialState: true
    })

    try {
      const validationResults = await validateConfiguration({
        encodedBusinessName,
        planningPeriodRange,
        ruleIdsToIgnore,
        selectedChoices
      })

      const { recoverySuggestions } = validationResults
      const { isValid } = validationResults

      commit(SET_VALIDATION, {
        results: recoverySuggestions,
        showBadge: true,
        isValidValidation: isValid,
        initialState: false
      })
      commit(UPDATE_POSSIBLE_COMBINATIONS_AFTER_VALIDATION, recoverySuggestions)

      return { validationResults: recoverySuggestions, status: 'success' }
    }
    catch (error) {
      commit(SET_VALIDATION)
      return {
        validationResults: [],
        status: 'error',
        errorMessage: error,
        initialState: true
      }
    }
  },
  async validateCurrentConfigurationV2({ commit, state, getters }, executePoll) {
    const {
      planningPeriodRange,
      ignoreList: ruleIdsToIgnore
    } = state.currentConfiguration || {}

    const { encodedBusinessName = '' } = state.currentConfiguration?.productModel || {}
    const { simplifiedSelectedChoices: selectedChoices } = getters

    commit(SET_VALIDATION, {
      isInProgress: true,
      isValidValidation: state.isValidValidation ?? false,
      initialState: true,
      errorCode: null
    })

    try {
      const { status, validationResponse, errorCode } = await executePoll({
        correlationIdEndpoint: {
          getCorrelationId: validateConfigurationV2,
          params: {
            encodedBusinessName,
            planningPeriodRange,
            ruleIdsToIgnore,
            selectedChoices
          }
        },
        asyncEndpoint: getValidateConfiguration
      })

      if (status === ASYNC_REQUEST_STATUS.STOPPED) return 0

      const { recoverySuggestions } = validationResponse
      const { isValid } = validationResponse

      commit(SET_VALIDATION, {
        results: recoverySuggestions,
        showBadge: true,
        isValidValidation: isValid,
        initialState: false,
        errorCode
      })
      commit(UPDATE_POSSIBLE_COMBINATIONS_AFTER_VALIDATION, recoverySuggestions)
      commit(SET_INVALID_OPTIONS, recoverySuggestions)

      return { validationResults: recoverySuggestions, status: 'success' }
    }
    catch (error) {
      commit(SET_VALIDATION)
      return {
        validationResults: [],
        status: 'error',
        errorMessage: error,
        initialState: true
      }
    }
  },
  removeSelectedChoiceFromConfiguration({ commit, state }, { optionId = '', choiceId = '' } = {}) {
    const wasLocked = !!state.lockedChoicesIds.get(optionId)?.includes(choiceId)

    commit(TOGGLE_CHOICE_SELECTION, {
      optionId,
      choice: { id: choiceId },
      wasSelected: true
    })

    if (wasLocked) {
      commit(TOGGLE_CHOICE_LOCK, { optionId, choiceId, wasLocked })
    }

    commit(REMOVE_CHOICE_FROM_INVALID_OPTIONS, { optionId, choiceId })

    commit(REMOVE_CHOICE_FROM_VALIDATION_RESULTS, choiceId)
    commit(CHANGED_CONFIGURATION, true)
  },
  removeAllInvalidChoicesFromConfiguration({ commit, state }) {
    state.validation.results.forEach(({ optionId, choiceId }) => {
      const wasLocked = !!state.lockedChoicesIds.get(optionId)?.includes(choiceId)

      commit(TOGGLE_CHOICE_SELECTION, {
        optionId,
        choice: { id: choiceId },
        wasSelected: true
      })

      if (wasLocked) {
        commit(TOGGLE_CHOICE_LOCK, { optionId, choiceId, wasLocked })
      }
      commit(REMOVE_CHOICE_FROM_INVALID_OPTIONS, { optionId, choiceId })
    })
    commit(SET_VALIDATION, { showBadge: false, initialState: true })
  },
  async getConfigSpecs({ commit }) {
    try {
      const configSpecs = await getWindchillConfigSpecs()
      const initialStructure = [
        {
          name: 'PRODUKTIVE GUELTIGKEIT IST', recommended: false
        },
        {
          name: 'PP', recommended: false
        },
        {
          name: 'PRODUKTIVE GUELTIGKEIT ZIEL', recommended: false
        }
      ]
      const configurationStructureOptions = new Map()
      const configSpecificationFilterOptions = new Map()

      configSpecs.forEach(configSpec => {
        configurationStructureOptions.set(configSpec.pmBusinessName,
          initialStructure.map(initial => {
            if (configSpec.specificationStructure === initial.name) {
              return { name: initial.name, recommended: true }
            }
            return initial
          }))
        configSpecificationFilterOptions.set(configSpec.pmBusinessName,
          initialStructure.map(initial => {
            if (configSpec.specificationOptionFilter === initial.name) {
              return { name: initial.name, recommended: true }
            }
            return initial
          }))
      })
      commit(SET_CONFIG_STRUCTURE_OPTIONS, configurationStructureOptions)
      commit(SET_CONFIG_SPECIFICATION_FILTER_OPTIONS, configSpecificationFilterOptions)
    }
    catch (errors) {
      console.error('Error loading the Filtering options', errors)
    }
  }
}

export default actions
