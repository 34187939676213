import { getNotificationState } from '@/api'
import {
  SET_NOTIFICATION_STATE,
  SET_NOTIFICATION_STATE_IS_LOADING
} from '@/store/modules/notifications/mutationTypes'

const actions = {
  async fetchNotificationsState({ commit }, {
    type,
    seen
  }) {
    commit(SET_NOTIFICATION_STATE_IS_LOADING, true)
    try {
      const { showNotification, timestamp } = await getNotificationState({ type, seen })
      commit(SET_NOTIFICATION_STATE, {
        type,
        availableNotifications: showNotification,
        lastSeenTimestamp: timestamp
      })
    }
    finally {
      commit(SET_NOTIFICATION_STATE_IS_LOADING, false)
    }
  }
}

export default actions
