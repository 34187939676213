import { SAVED_TABLES_ROUTE } from '@/router/routeNames'

const SavedTables = () => import(
  /* webpackChunkName: "saved-tables" */ '@/views/SavedTables.vue'
)

const routes = [
  {
    path: SAVED_TABLES_ROUTE.path,
    name: SAVED_TABLES_ROUTE.name,
    component: SavedTables,
    meta: {
      appFeatureSubRoute: SAVED_TABLES_ROUTE,
      title: SAVED_TABLES_ROUTE.title
    }
  }
]

export default routes
