import {
  TEST_CASES_ROUTE,
  TEST_CASE_VALIDATIONS_ROUTE,
  TEST_CASE_VALIDATION_RESULTS_ROUTE,
  TEST_CASE_VALIDATION_COMPARISON_ROUTE,
  UNUSABLE_CHOICES_VALIDATION_RESULTS_ROUTE,
  NOT_USUABLE_CHOICES_COMPARISON_ROUTE
} from '@/router/routeNames'

const TestCases = () => import(
  /* webpackChunkName: "test-cases" */ '@/components/validations/test-cases/TestCases.vue'
)
const TestCaseValidations = () => import(
  /* webpackChunkName: "test-case-validations" */ '@/components/validations/test-cases/TestCasesValidationsTable.vue'
)
const TestCaseValidationResults = () => import(
  /* webpackChunkName: "test-case-validation-results" */
  '@/components/validations/test-cases/validation-results/TestCaseValidationResults.vue'
)

const TestCaseValidationComparison = () => import(
  /* webpackChunkName: "test-case-validation-comparison" */
  '@/components/validations/test-cases/validation-results/TestCaseValidationComparison.vue'
)

const UnusableChoices = () => import(
  /* webpackChunkName: "unusable-choices-validation-results" */
  '@/components/validations/test-cases/unusable-choices/UnusableChoices.vue'
)

const NotUsableChoicesComparison = () => import(
  /* webpackChunkName: "not-usable-choices-comparison" */
  '@/components/validations/test-cases/not-usable-choices/NotUsableChoicesComparison.vue'
)

const routes = [
  {
    path: TEST_CASES_ROUTE.path,
    name: TEST_CASES_ROUTE.name,
    component: TestCases,
    meta: {
      appFeatureSubRoute: TEST_CASES_ROUTE,
      title: TEST_CASES_ROUTE.title
    },
    children: [{
      path: TEST_CASE_VALIDATIONS_ROUTE.path,
      name: TEST_CASE_VALIDATIONS_ROUTE.name,
      component: TestCaseValidations,
      meta: {
        title: TEST_CASE_VALIDATIONS_ROUTE.title
      }
    }]
  },
  {
    path: TEST_CASE_VALIDATION_RESULTS_ROUTE.path,
    name: TEST_CASE_VALIDATION_RESULTS_ROUTE.name,
    component: TestCaseValidationResults,
    meta: {
      appFeatureSubRoute: TEST_CASES_ROUTE,
      title: TEST_CASE_VALIDATION_RESULTS_ROUTE.title
    }
  },
  {
    path: TEST_CASE_VALIDATION_COMPARISON_ROUTE.path,
    name: TEST_CASE_VALIDATION_COMPARISON_ROUTE.name,
    component: TestCaseValidationComparison,
    meta: {
      appFeatureSubRoute: TEST_CASES_ROUTE,
      title: TEST_CASE_VALIDATION_COMPARISON_ROUTE.title
    }
  },
  {
    path: UNUSABLE_CHOICES_VALIDATION_RESULTS_ROUTE.path,
    name: UNUSABLE_CHOICES_VALIDATION_RESULTS_ROUTE.name,
    component: UnusableChoices,
    meta: {
      appFeatureSubRoute: TEST_CASES_ROUTE,
      title: TEST_CASE_VALIDATION_RESULTS_ROUTE.title
    }
  },
  {
    path: NOT_USUABLE_CHOICES_COMPARISON_ROUTE.path,
    name: NOT_USUABLE_CHOICES_COMPARISON_ROUTE.name,
    component: NotUsableChoicesComparison,
    meta: {
      appFeatureSubRoute: TEST_CASES_ROUTE,
      title: NOT_USUABLE_CHOICES_COMPARISON_ROUTE.title
    }
  }
]

export default routes
