import home from './home'
import maintenance from './maintenance'
import pac from './pac'
import validations from './validations'
import variantManagement from './variant-management'
import userManagement from './userManagement'
import userFeedback from './userFeedback'
import ruleValidations from './rule-validations'

export default [
  ...home,
  ...maintenance,
  ...pac,
  ...validations,
  ...variantManagement,
  ...userManagement,
  ...userFeedback,
  ...ruleValidations
]
