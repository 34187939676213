import {
  SET_APPLIED_ALIASES_IDS,
  SET_APPLIED_FILTERS,
  SET_APPLIED_SORTERS,
  SET_IS_COMBINATIONS_TABLE_LOADING,
  SET_COMBINATIONS_TABLE_LOADING_CONTENT
} from './mutationTypes'

const mutations = {
  [SET_APPLIED_ALIASES_IDS](state, aliasesIds = []) {
    state.appliedAliasesIds = aliasesIds
  },
  [SET_APPLIED_FILTERS](state, filters = []) {
    state.appliedFilters = filters
  },
  [SET_APPLIED_SORTERS](state, sorters = []) {
    state.appliedSorters = sorters
  },
  [SET_IS_COMBINATIONS_TABLE_LOADING](state, isLoading = false) {
    state.isCombinationsTableLoading = !!isLoading
  },
  [SET_COMBINATIONS_TABLE_LOADING_CONTENT](state, content = '') {
    state.combinationsTableLoadingContent = content
  }
}

export default mutations
