import { getProductModels, getProductModelsCounts } from '@/api'
import {
  UPDATE_PRODUCT_MODELS_LIST,
  SET_PRODUCT_MODEL_LOADING_STATE,
  SET_PRODUCT_MODEL_COUNTS,
  SET_PRODUCT_MODEL_COUNTS_LOADING_STATE
} from './mutationTypes'

const actions = {
  async getProductModelsList({ commit }) {
    commit(SET_PRODUCT_MODEL_LOADING_STATE, true)

    try {
      const productModels = await getProductModels()

      commit(UPDATE_PRODUCT_MODELS_LIST, productModels)

      return productModels
    }
    finally {
      commit(SET_PRODUCT_MODEL_LOADING_STATE, false)
    }
  },
  async getProductModelsCount({ commit, getters }) {
    commit(SET_PRODUCT_MODEL_COUNTS_LOADING_STATE, true)

    try {
      const { queryProductModels: queryParam } = getters
      const { id, encodedBusinessName } = queryParam
      const productModelsCount = await getProductModelsCounts(id, encodedBusinessName)

      commit(SET_PRODUCT_MODEL_COUNTS, productModelsCount)
    }
    finally {
      commit(SET_PRODUCT_MODEL_COUNTS_LOADING_STATE, false)
    }
  }
}

export default actions
