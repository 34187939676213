import {
  BROWSE_PORFOLIO_ROUTE
} from '@/router/routeNames'

const BrowsePorfolio = () => import(
  /* webpackChunkName: "test-cases" */ '@/views/BrowsePortfolio.vue'
)

const routes = [
  {
    path: BROWSE_PORFOLIO_ROUTE.path,
    name: BROWSE_PORFOLIO_ROUTE.name,
    component: BrowsePorfolio,
    meta: {
      appFeatureSubRoute: BROWSE_PORFOLIO_ROUTE,
      title: BROWSE_PORFOLIO_ROUTE.title
    }
  }
]

export default routes
