export const SET_CATEGORIES_RESULTS = 'SET_CATEGORIES_RESULTS'
export const SET_OPTIONS_RESULTS = 'SET_OPTIONS_RESULTS'
export const SET_CHOICES_RESULTS = 'SET_CHOICES_RESULTS'
export const SET_SEARCH_DATA = 'SET_SEARCH_DATA'
export const SET_SEARCH_CHOICES_DATA = 'SET_SEARCH_CHOICES_DATA'
export const RESET_WORLD = 'RESET_WORLD'
export const SET_IS_LOADING = 'SET_IS_LOADING'
export const SET_HIGH_GROUPS = 'SET_HIGH_GROUPS'
export const SET_COMPONENTS = 'SET_COMPONENTS'
export const SET_COMPONENT_VARIANTS = ' SET_COMPONENT_VARIANTS'
