import { ELEMENT_COLORS, SEARCH_ELEMENT_TYPES, SEARCH_RESULT_TYPES, RESULTS_FILTER } from '@/constants'

const state = {
  areElementsLoading: false,
  isSearchPerformed: false,
  selectedElementType: '',
  showResultsLater: false,
  areCombinationsLoading: false,
  selectedResultType: SEARCH_RESULT_TYPES.OPTIONS,
  resultsFilter: {
    [RESULTS_FILTER.SHOW_CODE_ID]: true,
    [RESULTS_FILTER.SHOW_NAME]: true
  },
  resultsFilterExcel: {
    [RESULTS_FILTER.SHOW_CODE_ID]: true,
    [RESULTS_FILTER.SHOW_NAME]: true,
    [RESULTS_FILTER.SPLIT_CODE_ID_AND_NAME]: false
  },
  resultsFilterSearch: {
    [RESULTS_FILTER.SHOW_CODE_ID]: true,
    [RESULTS_FILTER.SHOW_NAME]: true
  },
  elements: {
    [SEARCH_ELEMENT_TYPES.STRUCTURE]: {
      [SEARCH_RESULT_TYPES.OPTIONS]: {
        items: [],
        totalSize: 0,
        color: ELEMENT_COLORS.YELLOW,
        icon: 'options',
        label: 'Option(s)'
      },
      [SEARCH_RESULT_TYPES.CHOICES]: {
        items: [],
        totalSize: 0,
        color: ELEMENT_COLORS.YELLOW,
        icon: 'choices',
        label: 'Choice(s)'
      },
      [SEARCH_RESULT_TYPES.COMPONENTS]: {
        items: [],
        totalSize: 0,
        color: ELEMENT_COLORS.GREEN,
        icon: 'components',
        label: 'Component(s)'
      },
      [SEARCH_RESULT_TYPES.COMPONENT_VARIANTS]: {
        items: [],
        totalSize: 0,
        color: ELEMENT_COLORS.GREEN,
        icon: 'component-variants',
        label: 'Component Variant(s)'
      }
    }
  },
  previewElements: {
    [SEARCH_RESULT_TYPES.OPTIONS]: [],
    [SEARCH_RESULT_TYPES.COMPONENTS]: []
  },
  previewElementsIds: {
    [SEARCH_RESULT_TYPES.OPTIONS]: new Map(),
    [SEARCH_RESULT_TYPES.COMPONENTS]: new Map()
  },
  resetSelectedElementsIds: new Map(),
  selectedElements: {
    [SEARCH_RESULT_TYPES.OPTIONS]: [],
    [SEARCH_RESULT_TYPES.COMPONENTS]: []
  },
  selectedElementsIds: {
    [SEARCH_RESULT_TYPES.OPTIONS]: new Map(),
    [SEARCH_RESULT_TYPES.COMPONENTS]: new Map()
  },
  showEditSolutionElements: false
}

export default state
