import { SEARCH_ELEMENT_TYPES, SEARCH_RESULT_TYPES } from '@/constants'

const getters = {
  areSelectedElementsEmpty({ selectedElements = {} } = {}) {
    return !(selectedElements[SEARCH_RESULT_TYPES.OPTIONS]?.length
      || selectedElements[SEARCH_RESULT_TYPES.COMPONENTS]?.length
      || selectedElements[SEARCH_RESULT_TYPES.RULES]?.length)
  },
  currentPreviewElements({ previewElements = {}, selectedResultType = '' } = {}) {
    let currentPreviewElements = []

    if ([SEARCH_RESULT_TYPES.OPTIONS, SEARCH_RESULT_TYPES.CHOICES].includes(selectedResultType)) {
      currentPreviewElements = previewElements[SEARCH_RESULT_TYPES.OPTIONS] || []
    }
    else if ([SEARCH_RESULT_TYPES.COMPONENTS, SEARCH_RESULT_TYPES.COMPONENT_VARIANTS].includes(selectedResultType)) {
      currentPreviewElements = previewElements[SEARCH_RESULT_TYPES.COMPONENTS] || []
    }

    return currentPreviewElements
  },
  currentPreviewElementsIds({ previewElementsIds = {}, selectedResultType = '' } = {}) {
    let currentPreviewElementsIds = []

    if ([SEARCH_RESULT_TYPES.OPTIONS, SEARCH_RESULT_TYPES.COMPONENTS].includes(selectedResultType)) {
      currentPreviewElementsIds = [...previewElementsIds[selectedResultType].keys()]
    }
    else if (SEARCH_RESULT_TYPES.CHOICES === selectedResultType) {
      previewElementsIds[SEARCH_RESULT_TYPES.OPTIONS].forEach(value => {
        currentPreviewElementsIds.push(...value)
      })
    }
    else if (SEARCH_RESULT_TYPES.COMPONENT_VARIANTS === selectedResultType) {
      previewElementsIds[SEARCH_RESULT_TYPES.COMPONENTS].forEach(value => {
        currentPreviewElementsIds.push(...value)
      })
    }

    return currentPreviewElementsIds
  },
  currentResultsType({ elements = {}, selectedResultType = '' } = {}) {
    return elements[SEARCH_ELEMENT_TYPES.STRUCTURE]?.[selectedResultType] || { items: [], totalSize: 0 }
  },
  currentSelectedElementsIdsLength({ selectedElementsIds = {} } = {}) {
    const elementsIds = []

    Object.values(selectedElementsIds).forEach(selectedElements => {
      const keys = selectedElements ? [...selectedElements.keys()] : []
      const values = selectedElements ? [...selectedElements.values()].flat() : []
      elementsIds.push(...keys, ...values)
    })

    return elementsIds.length
  },
  selectedComponents({ selectedElements = {} } = {}) {
    return selectedElements[SEARCH_RESULT_TYPES.COMPONENTS]
  },
  selectedOptions({ selectedElements = {} } = {}) {
    return selectedElements[SEARCH_RESULT_TYPES.OPTIONS]
  },
  selectedElements({ selectedElementsIds = {} } = {}) {
    const defaultValue = { [SEARCH_RESULT_TYPES.COMPONENTS]: [], [SEARCH_RESULT_TYPES.OPTIONS]: [] }

    const counter = (acc, resultType) => {
      selectedElementsIds[resultType].forEach(elements => {
        acc[resultType] = [...acc[resultType], ...elements.values()]
      })
      return acc
    }

    return Object.keys(selectedElementsIds).reduce(counter, defaultValue)
  },
  selectedElementsForLes({ selectedElements = {} } = {}) {
    return selectedElements.components || []
  }
}

export default getters
