const state = {
  whatsNew: {
    whatsNewData: {},
    whatsModalNewData: {},
    filteredNewData: {},
    whatsNewIsLoading: false
  }
}

export default state
