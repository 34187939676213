import { getModalWhatsNewData, getWhatsNewData } from '@/api'
import {
  SET_FILTERED_WHATS_NEW_DATA, SET_MODAL_WHATS_NEW_DATA,
  SET_WHATS_NEW_DATA,
  SET_WHATS_NEW_DATA_IS_LOADING
} from '@/store/modules/whatsNew/mutationTypes'
import { PRODUCT_AREA_FILTER } from '@/constants/whatsNew'

const actions = {
  async fetchWhatsNewData({ commit }) {
    commit(SET_WHATS_NEW_DATA_IS_LOADING, true)
    try {
      const data = await getWhatsNewData()
      commit(SET_WHATS_NEW_DATA, data)
      commit(SET_FILTERED_WHATS_NEW_DATA, PRODUCT_AREA_FILTER[0].name)
    }
    finally {
      commit(SET_WHATS_NEW_DATA_IS_LOADING, false)
    }
  },
  async fetchModalWhatsNewData({ commit }, timestampStart) {
    commit(SET_WHATS_NEW_DATA_IS_LOADING, true)
    try {
      const data = await getModalWhatsNewData({ timestampStart })
      commit(SET_MODAL_WHATS_NEW_DATA, data)
    }
    finally {
      commit(SET_WHATS_NEW_DATA_IS_LOADING, false)
    }
  }
}

export default actions
