import { getCategories, getHighGroups, getOptions, getComponents } from '@/api/rest/browse-portfolio'
import {
  RESET_WORLD,
  SET_CATEGORIES_RESULTS,
  SET_CHOICES_RESULTS,
  SET_HIGH_GROUPS,
  SET_IS_LOADING,
  SET_OPTIONS_RESULTS,
  SET_COMPONENTS,
  SET_COMPONENT_VARIANTS
} from '@/store/modules/browse-portfolio/mutationTypes'

const highGroupsState = 'highGroups'
const componentsState = 'components'
const categoriesState = 'categoriesItems'
const optionsState = 'optionsItems'

const actions = {
  async fetchCategories({ commit }, pmEncodedBusinessName) {
    commit(SET_IS_LOADING, { type: categoriesState, isLoading: true })
    try {
      const categories = await getCategories(pmEncodedBusinessName)
      commit(SET_CATEGORIES_RESULTS, Object.freeze(categories))
    }
    finally {
      commit(SET_IS_LOADING, { type: categoriesState, isLoading: false })
    }
  },
  async fetchOptions({ commit }, itemData) {
    commit(RESET_WORLD)
    commit(SET_IS_LOADING, { type: optionsState, isLoading: true })
    try {
      const { id, isActive, pmEncodedBusinessName } = itemData

      const results = isActive ? await getOptions(id, pmEncodedBusinessName) : []

      commit(SET_OPTIONS_RESULTS, { results, id, isActive })
      if (!isActive) commit(RESET_WORLD)
    }
    finally {
      commit(SET_IS_LOADING, { type: optionsState, isLoading: false })
    }
  },
  fetchChoices({ commit }, itemData) {
    commit(SET_CHOICES_RESULTS, itemData)
  },
  async fetchHighGroups({ commit }, pmEncodedBusinessName) {
    commit(SET_IS_LOADING, { type: highGroupsState, isLoading: true })
    try {
      const highGroups = await getHighGroups(pmEncodedBusinessName)
      commit(SET_HIGH_GROUPS, Object.freeze(highGroups))
    }
    finally {
      commit(SET_IS_LOADING, { type: highGroupsState, isLoading: false })
    }
  },
  async fetchComponents({ commit }, itemData) {
    commit(RESET_WORLD)
    commit(SET_COMPONENTS, { type: componentsState, isLoading: true })
    try {
      const { id, isActive, pmEncodedBusinessName } = itemData

      const results = isActive ? await getComponents(id, pmEncodedBusinessName) : []

      commit(SET_COMPONENTS, { results, id, isActive })
      if (!isActive) commit(RESET_WORLD)
    }
    finally {
      commit(SET_IS_LOADING, { type: componentsState, isLoading: false })
    }
  },
  async fetchComponentVariants({ commit }, itemData) {
    commit(SET_COMPONENT_VARIANTS, itemData)
  }
}

export default actions
