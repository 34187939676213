import { BOM_VALIDATION_RESULT_TYPES } from '@/constants'

const state = {
  userExports: {
    resultsTypes: [],
    resultsType: [],
    results: [],
    filteredResults: [],
    isInProgress: true,
    size: 0,
    selectedUserExport: {},
    selectedUserExportType: '',
    filters: []
  },
  validations: {
    results: [],
    isInProgress: true,
    size: 0,
    selectedUserExport: {}
  },
  validation: {
    results: [],
    totalErrorTypes: {
      componentVariant: [],
      solutionElement: [],
      transformation: []
    },
    selectedValidationType: BOM_VALIDATION_RESULT_TYPES.COMPONENT_VARIANT.key,
    isInProgress: true,
    size: 0,
    selectedUserExport: {},
    summaryData: []
  },
  testCasesFiles: [],
  selectedUserExportType: ''
}

export default state
