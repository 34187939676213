// Updates page title for each view
export const updatePageTitle = (to = {}) => {
  const nearestWithTitle = [...to.matched].reverse().find(route => route.meta?.title) || {}
  let title = process.env.VUE_APP_NAME

  if (Object.keys(nearestWithTitle).length) {
    title = `${ nearestWithTitle.meta?.title } · ${ process.env.VUE_APP_NAME }`
  }

  document.title = title
}
