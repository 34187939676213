import { NOT_FOUND_ROUTE, NOT_AUTHORIZED_ROUTE, NOT_ALLOWED_ROUTE } from '@/router/routeNames'

const NotFoundPage = () => import(/* webpackChunkName: "not-found-page" */ '@/views/NotFound.vue')
const NotAuthorizedPage = () => import(/* webpackChunkName: "not-authorized-page" */ '@/views/NotAuthorized.vue')
const NotAllowedPage = () => import(/* webpackChunkName: "not-allowed-page" */ '@/views/NotAllowed.vue')

const routes = [
  {
    path: NOT_FOUND_ROUTE.path,
    name: NOT_FOUND_ROUTE.name,
    component: NotFoundPage,
    meta: {
      title: NOT_FOUND_ROUTE.title,
      authorities: []
    }
  },
  {
    path: NOT_AUTHORIZED_ROUTE.path,
    name: NOT_AUTHORIZED_ROUTE.name,
    component: NotAuthorizedPage,
    meta: {
      title: NOT_AUTHORIZED_ROUTE.title,
      authorities: []
    }
  },
  {
    path: NOT_ALLOWED_ROUTE.path,
    name: NOT_ALLOWED_ROUTE.name,
    component: NotAllowedPage,
    meta: {
      title: NOT_ALLOWED_ROUTE.title,
      authorities: []
    }
  }
]

export default routes
