import { NOTIFICATION_TYPES } from '@/constants/notifications'
import {
  SET_NOTIFICATION_STATE,
  SET_NOTIFICATION_STATE_IS_LOADING
} from './mutationTypes'

const mutations = {
  [SET_NOTIFICATION_STATE](state, { type, availableNotifications, lastSeenTimestamp }) {
    if (type === NOTIFICATION_TYPES.whatsNew) {
      state.newNotificationAvailable = availableNotifications
    }
    if (type === NOTIFICATION_TYPES.whatsNewModal) {
      state.newModalNotificationAvailable = availableNotifications
    }
    state.lastSeenTimestamp = lastSeenTimestamp
  },
  [SET_NOTIFICATION_STATE_IS_LOADING](state, notificationsLoading) {
    state.notificationsLoading = notificationsLoading
  }
}

export default mutations
