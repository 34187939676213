import {
  searchChoices,
  searchComponents,
  searchOptions,
  getSummarySelection,
  searchComponentVariant
} from '@/api'

import { SEARCH_ELEMENT_TYPES, SEARCH_RESULT_TYPES, ELEMENT_STATES } from '@/constants'
import {
  SET_COMPONENTS_FOR_LE,
  SET_ELEMENTS,
  TOGGLE_ELEMENT_STATE,
  UPDATE_SELECTED_ELEMENTS,
  SET_RESULTS_FOR_LATER,
  SET_COMBINATIONS_LOADING,
  REPLACE_SELECTED_ELEMENTS,
  UPDATE_ELEMENTS
} from './mutationTypes'

const getElements = (elements, resultType, newElements) => elements.map(
  el => newElements[resultType].find(({ id2 }) => id2 === el.id2) || el
)

const actions = {
  async getChoices({ commit }, {
    pmType = '',
    pmIdentifier = '',
    query = '',
    numberOfElements = -1
  } = {}) {
    const { elements: items, totalSize } = await searchChoices({
      pmType,
      pmIdentifier,
      query,
      numberOfElements
    })

    commit(SET_ELEMENTS, {
      elementType: SEARCH_ELEMENT_TYPES.STRUCTURE,
      resultType: SEARCH_RESULT_TYPES.CHOICES,
      items,
      totalSize
    })
  },
  async getComponentVariants({ commit }, {
    pmType = '',
    pmIdentifier = '',
    query = '',
    numberOfElements = -1
  } = {}) {
    const {
      elements: items,
      totalSize
    } = await searchComponentVariant({
      pmType,
      pmIdentifier,
      query,
      numberOfElements
    })

    commit(SET_ELEMENTS, {
      elementType: SEARCH_ELEMENT_TYPES.STRUCTURE,
      resultType: SEARCH_RESULT_TYPES.COMPONENT_VARIANTS,
      items,
      totalSize
    })
  },
  async getComponents({ commit }, {
    pmType = '',
    pmIdentifier = '',
    query = '',
    numberOfElements = -1
  } = {}) {
    const { elements: items, totalSize } = await searchComponents({
      pmType,
      pmIdentifier,
      query,
      numberOfElements
    })

    commit(SET_ELEMENTS, {
      elementType: SEARCH_ELEMENT_TYPES.STRUCTURE,
      resultType: SEARCH_RESULT_TYPES.COMPONENTS,
      items,
      totalSize
    })
  },
  async getOptions({ commit }, {
    pmType = '',
    query = '',
    numberOfElements = -1,
    pmIdentifier = ''
  } = {}) {
    const { elements: items, totalSize } = await searchOptions({
      query,
      numberOfElements,
      pmIdentifier,
      pmType
    })

    commit(SET_ELEMENTS, {
      elementType: SEARCH_ELEMENT_TYPES.STRUCTURE,
      resultType: SEARCH_RESULT_TYPES.OPTIONS,
      items,
      totalSize
    })
  },
  async getOptionWithChoices({ commit }, { option = {}, isInPreview = false } = {}) {
    const { choices } = option

    commit(TOGGLE_ELEMENT_STATE, {
      element: { ...option, choices },
      elementsType: SEARCH_RESULT_TYPES.OPTIONS,
      elementState: ELEMENT_STATES.IN_PREVIEW,
      subElementsType: SEARCH_RESULT_TYPES.CHOICES,
      isChosen: isInPreview
    })
  },
  async getComponentWithVariants({ commit }, {
    component = {},
    isInPreview = false
  } = {}) {
    const { componentVariants } = component

    commit(TOGGLE_ELEMENT_STATE, {
      element: { ...component, componentVariants },
      elementsType: SEARCH_RESULT_TYPES.COMPONENTS,
      elementState: ELEMENT_STATES.IN_PREVIEW,
      subElementsType: SEARCH_RESULT_TYPES.COMPONENT_VARIANTS,
      isChosen: isInPreview
    })
  },
  async getCurrentSelection({ commit }) {
    const { options, components, pmEncodedBusinessName } = await getSummarySelection()

    commit(UPDATE_SELECTED_ELEMENTS, {
      elementsType: SEARCH_RESULT_TYPES.OPTIONS,
      subElementsType: SEARCH_RESULT_TYPES.CHOICES,
      elements: options
    })
    commit(UPDATE_SELECTED_ELEMENTS, {
      elementsType: SEARCH_RESULT_TYPES.COMPONENTS,
      subElementsType: SEARCH_RESULT_TYPES.COMPONENT_VARIANTS,
      elements: components
    })
    return pmEncodedBusinessName
  },

  setComponentsForLE({ commit }, { selectedForLE = false } = {}) {
    commit(SET_COMPONENTS_FOR_LE, {
      selectedForLE,
      elementsType: SEARCH_RESULT_TYPES.COMPONENTS
    })
  },
  setResultsForLater({ commit }, { showResultsLater = false } = {}) {
    commit(SET_RESULTS_FOR_LATER, { showResultsLater })
  },
  setCombinationsLoading({ commit }, { areCombinationsLoading = false } = {}) {
    commit(SET_COMBINATIONS_LOADING, { areCombinationsLoadingValue: areCombinationsLoading })
  },
  updateElements({ commit }, { elements, resultType, subElementsType } = {}) {
    commit(UPDATE_ELEMENTS, { resultType, subElementsType, newElements: elements })
  },
  replaceSelectedElements({ commit }, { elements, elementsType, subElementsType } = {}) {
    commit(REPLACE_SELECTED_ELEMENTS, { elementsType, subElementsType, elements })
  },
  updateListIfElementIdChange({ state, dispatch }, { detectElementsData } = {}) {
    const options = getElements(state.selectedElements.options, SEARCH_RESULT_TYPES.OPTIONS, detectElementsData)

    const components = getElements(
      state.selectedElements.components, SEARCH_RESULT_TYPES.COMPONENTS, detectElementsData
    )

    dispatch('replaceSelectedElements', {
      elementsType: SEARCH_RESULT_TYPES.OPTIONS,
      subElementsType: SEARCH_RESULT_TYPES.CHOICES,
      elements: options
    })

    dispatch('replaceSelectedElements', {
      elementsType: SEARCH_RESULT_TYPES.COMPONENTS,
      subElementsType: SEARCH_RESULT_TYPES.COMPONENT_VARIANTS,
      elements: components
    })

    dispatch('updateElements', {
      elements: options,
      resultType: SEARCH_RESULT_TYPES.OPTIONS,
      subElementsType: SEARCH_RESULT_TYPES.CHOICES
    })

    dispatch('updateElements', {
      elements: components,
      resultType: SEARCH_RESULT_TYPES.COMPONENTS,
      subElementsType: SEARCH_RESULT_TYPES.COMPONENT_VARIANTS
    })
  }
}

export default actions
