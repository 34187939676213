import { PRODUCT_AREA_FILTER } from '@/constants/whatsNew'
import {
  SET_FILTERED_WHATS_NEW_DATA,
  SET_MODAL_WHATS_NEW_DATA,
  SET_WHATS_NEW_DATA, SET_WHATS_NEW_DATA_IS_LOADING
} from './mutationTypes'

const mutations = {
  [SET_WHATS_NEW_DATA](state, whatsNewData = {}) {
    state.whatsNewData = whatsNewData.sort((a, b) => {
      const timestampA = new Date(a.version_timestamp)
      const timestampB = new Date(b.version_timestamp)
      return timestampB - timestampA
    })
  },
  [SET_MODAL_WHATS_NEW_DATA](state, whatsNewData = {}) {
    state.whatsModalNewData = whatsNewData.flatMap(group => JSON.parse(group.data)
      .map(item => ({
        title: item.content.title,
        topics: item.content.topics,
        section: item.section,
        type: item.type
      })))
      .reduce((acc, item) => {
        const existingEntry = acc.find(entry => entry.title === item.title)
        if (existingEntry) {
          existingEntry.topics = existingEntry.topics.concat(item.topics)
          existingEntry.section = item.section
          existingEntry.type = item.type
        }
        else {
          acc.push(item)
        }
        return acc
      }, [])
  },
  [SET_FILTERED_WHATS_NEW_DATA](state, name = '') {
    // check if it's display all
    if (PRODUCT_AREA_FILTER[0].name === name) {
      state.filteredNewData = state.whatsNewData
    }
    else {
      state.filteredNewData = state.whatsNewData.map(({ data, ...entry }) => ({
        ...entry,
        data: JSON.parse(data).filter(content => content.product_name === name)
      })).filter(({ data }) => data.length > 0)
    }
  },
  [SET_WHATS_NEW_DATA_IS_LOADING](state, whatsNewIsLoading) {
    state.whatsNewIsLoading = whatsNewIsLoading
  }
}

export default mutations
