import { COMBINATIONS_ROUTE, SEARCH_ROUTE } from '@/router/routeNames'

const Search = () => import(/* webpackChunkName: "search" */ '@/views/Search.vue')
const Combinations = () => import(/* webpackChunkName: "combinations" */ '@/components/combinations/Combinations.vue')

const routes = [
  {
    path: SEARCH_ROUTE.path,
    name: SEARCH_ROUTE.name,
    component: Search,
    meta: {
      appFeatureSubRoute: SEARCH_ROUTE,
      isProductModelRequired: true,
      title: SEARCH_ROUTE.title,
      staticWidth: true,
      authorities: ['SEARCH_PM_CONTENT']
    },
    children: [
      {
        path: COMBINATIONS_ROUTE.path,
        name: COMBINATIONS_ROUTE.name,
        component: Combinations,
        meta: {
          title: COMBINATIONS_ROUTE.title
        },
        props: route => ({ correlationId: route.query.correlationId })
      }
    ]
  }
]

export default routes
