import { mapDataBasedOnList } from '@/utils/recursiveFunc/recursiveMap'

const getters = {
  hasSelectedUserExport(state) {
    return !!Object.keys(state.userExports.selectedUserExport).length
  },
  hasUserExports(state) {
    return state.userExports?.size
  },
  isGettingUserExports(state) {
    return state.userExports?.isInProgress
  },
  isUnavailableUserExports(state) {
    return !(state.userExports?.isInProgress || state.userExports?.size)
  },
  hasValidations(state) {
    return state.validations?.size
  },
  getUserExportType(state) {
    return state.selectedUserExportType
  },
  getSelectedUserExport(state) {
    return state.userExports.selectedUserExport
  },
  getMappedSummaryDetails() {
    const x = {
      id: '63b6cfb911014425daaffb01',
      name: 'Workaround_ETAA',
      creationDate: '2023-01-05T08:08:46',
      creator: 'eu.man.core.engine',
      businessName: 'Workaround ETAA',
      reference: 'UserExport_XMLRegelwerkKonverterQueue__20220929-160000.json',
      onDemandExportMetadata: {
        creator: 'c7455',
        exportName: 'Workaround',
        createdAt: '29.09.2022 09:25',
        containsBasicRules: false,
        containsBOM: true,
        conditionalRulesConfig: 'RELEASED_FOR_PROTOTYPE',
        tarStateConfig: 'INWORK'
      },
      encodedBusinessName: 'V29ya2Fyb3VuZCBFVEFB'
    }

    const keys = [
      { encodedBusinessName: 'OLA', creationDate: 'DATA XPTO' },
      { name: 'Export Name' },
      { creationDate: 'creationDate' }
    ]
    return mapDataBasedOnList(x, keys)
  },
  getAmbiguousData(state) {
    return state.validation.totalErrorTypes[state.validation.selectedValidationType].ambiguousElements || []
  },
  getIncompleteData(state) {
    return state.validation.totalErrorTypes[state.validation.selectedValidationType].incompleteElements || []
  },
  getSummaryData(state) {
    return state.validation.summaryData
  }
}

export default getters
