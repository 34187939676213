import { BOM_VALIDATION_IN_PROGRESS } from '@/constants'
import {
  SET_BOM_VALIDATIONS,
  SET_CURRENT_BOM_VALIDATION,
  SET_IS_NEW_VALIDATION_DIALOG_SHOWN,
  SET_TEST_CASES,
  SET_TEST_CASE_VALIDATIONS,
  SET_VALIDATIONS_SEARCH_STRING,
  SET_IN_PROGRESS_BOM_VALIDATIONS,
  SET_BOM_VALIDATION_COMPONENTS,
  SET_BOM_VALIDATION_IS_LOADING,
  SET_FILTERED_USER_VALIDATIONS,
  HANDLE_INITIAL_STATE_SELECTED_EXPORT,
  SET_FILTER_USER_VALIDATIONS,
  SET_FILTER_TEST_CASES,
  ARE_TEST_CASES_LOADING,
  SET_EXPORT_DATA_VISIBILITY,
  SET_TEST_CASE_FILTERS,
  SET_TEST_CASE_PAGE,
  RESET_TEST_CASE_FILTERS
} from './mutationTypes'

const mutations = {
  [SET_BOM_VALIDATIONS](state, bomValidations = []) {
    state.bomValidations = bomValidations
  },
  [SET_IN_PROGRESS_BOM_VALIDATIONS](state, bomValidations = []) {
    state.bomValidationsInProgress = bomValidations
      .filter(bomValidation => bomValidation.status === BOM_VALIDATION_IN_PROGRESS)
  },
  [SET_CURRENT_BOM_VALIDATION](state, bomValidation = {}) {
    state.currentBomValidation = bomValidation
  },
  [SET_IS_NEW_VALIDATION_DIALOG_SHOWN](state, isDialogShown = false) {
    state.isNewValidationDialogShown = isDialogShown
  },
  [SET_TEST_CASES](state, testCases = []) {
    state.testCases = testCases
    state.filteredTestCases.results = testCases
  },
  [SET_TEST_CASE_VALIDATIONS](state, testCaseValidations = []) {
    state.testCaseValidations = testCaseValidations
  },
  [SET_VALIDATIONS_SEARCH_STRING](state, searchString = '') {
    state.validationsSearchString = searchString
  },
  [SET_BOM_VALIDATION_COMPONENTS](state, bomValidationComponents = {}) {
    state.bomValidationComponents = bomValidationComponents
  },
  [SET_BOM_VALIDATION_IS_LOADING](state, bomValidationIsLoading = false) {
    state.isBomValidationLoading = bomValidationIsLoading
  },
  [SET_FILTER_USER_VALIDATIONS](state, value) {
    const index = state.userBomValidations.filters.findIndex(filter => filter.key === value.key)

    if (index !== -1) {
      state.userBomValidations.filters.splice(index, 1)
    }
    else {
      state.userBomValidations.filters.push(value)
    }
  },
  [SET_FILTERED_USER_VALIDATIONS](state) {
    const filterDataByCriteria = (filters, data) => data.filter(item => filters.every(filter => {
      const { key, value } = filter
      return (key === 'creator') ? item.user.toString() === value : item.startDate.startsWith(value)
    }))

    state.userBomValidations.filteredResults = state.userBomValidations.filters.length
      ? filterDataByCriteria(state.userBomValidations.filters, state.bomValidations)
      : state.bomValidations
    state.userBomValidations.size = state.userBomValidations.filteredResults?.length ?? 0
  },
  [HANDLE_INITIAL_STATE_SELECTED_EXPORT](state) {
    const isSelectedUserExportValid = state.userBomValidations.filteredResults
      .find(result => state.userBomValidations.selectedUserExport.id === result.id)
    if (!isSelectedUserExportValid) {
      state.userBomValidations.selectedUserExportType = ''
      state.userBomValidations.selectedUserExport = {}
    }
  },
  [SET_FILTER_TEST_CASES](state, value) {
    const index = state.filteredTestCases.filters.findIndex(filter => filter.key === value.key)

    if (index !== -1) {
      state.filteredTestCases.filters.splice(index, 1)
    }
    else {
      state.filteredTestCases.filters.push(value)
    }
  },
  [ARE_TEST_CASES_LOADING](state, value) {
    state.areTestCasesLoading = value
  },
  [SET_EXPORT_DATA_VISIBILITY](state, value) {
    state.bomCurrentElements.hasAnyErrorTypeResults = value
  },
  [SET_TEST_CASE_FILTERS](state, value) {
    const { type, val } = value
    state.testCaseFilters[type] = val
  },
  [SET_TEST_CASE_PAGE](state, value) {
    state.testCasePageable = { ...state.testCasePageable, ...value }
  },
  [RESET_TEST_CASE_FILTERS](state, value) {
    state.testCaseFilters = value
  }
}

export default mutations
