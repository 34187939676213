import LocalStorage from 'quasar/src/plugins/LocalStorage.js';

import { Auth } from 'aws-amplify'

import store from '@/store'
import notify from '@/utils/notify'
import getUserSession from '@/utils/getUserSession'
import { SET_USER } from '@/store/mutationTypes'
import { NOT_AUTHORIZED_ROUTE, PRODUCT_MODELS_ROUTE, HOME_ROUTE, NOT_ALLOWED_ROUTE } from '@/router/routeNames'

// Guard notifications
const notifyAboutDeniedAccess = () => {
  notify({
    title: 'Access denied',
    content: 'We are sorry, but you don\'t have access to the requested page',
    type: 'negative'
  })
}

const notifyAboutMissingProductModel = () => {
  notify({
    title: 'Product model is missing',
    content: 'Please, select any product model before accessing requested page',
    type: 'warning'
  })
}

// Checks if user has authorities to access requested page
// eslint-disable-next-line consistent-return
const hasUserAccessToThePage = ({ user = {}, pageAuthorities = [] } = {}) => {
  if (user.authorities === null || user.authorities === undefined) return false
  return !!((pageAuthorities?.every(authority => user.authorities.includes(authority))))
}

const removeCognitoFromLocalStorage = () => {
  const localStorageKeys = LocalStorage.getAllKeys()
  localStorageKeys.forEach(key => {
    if (key.match(/^CognitoIdentityServiceProvider/g)) LocalStorage.remove(key)
  })
}

const signIn = async redirectPage => {
  let user
  try {
    await Auth.federatedSignIn({ provider: 'Azure' }).catch({})
    const { user: newUser, token: newToken } = await getUserSession()
    LocalStorage.set(process.env.VUE_APP_STORAGE_KEY_TOKEN, newToken)
    LocalStorage.set(process.env.VUE_APP_STORAGE_KEY_AFTER_SIGN_IN_REDIRECT_TO, redirectPage)
    user = newUser
    store.commit(SET_USER, user)
  }
  catch (e) {
    user = {}
  }
  return user
}

const getUserInfo = async () => {
  let { user } = store.state || {}
  const token = LocalStorage.getItem(process.env.VUE_APP_STORAGE_KEY_TOKEN)

  if (!(token && user.username)) {
    try {
      const { user: newUser, token: newToken } = await getUserSession()
      LocalStorage.set(process.env.VUE_APP_STORAGE_KEY_TOKEN, newToken)
      user = newUser
      store.commit(SET_USER, user)
    }
    catch (e) {
      LocalStorage.remove(process.env.VUE_APP_STORAGE_KEY_TOKEN)
      user = {}
    }
  }

  return user
}

const isRouteUnderMaintenance = ({ user, path, maintenanceRoute }) => {
  if (!maintenanceRoute || !user) return false
  const notAllowedPage = path === maintenanceRoute
  const hasADmin = user.authorities?.includes('ADMIN') || false
  return notAllowedPage && !hasADmin
}

/**
 * The following guard validates:
 * - token and authorities (if provided) of the user for each route that requires auth;
 * - product model in localStorage for each route that requires it to be selected;
 *
 * Redirects to 'auth' page if no token found in localStorage.
 * Redirects to 'productModels' page if no product model found in localStorage.
 */
export const validateUserTokenAndAuthorities = async (to, from, next) => {
  const { id: productModelId } = LocalStorage.getItem(process.env.VUE_APP_STORAGE_KEY_PRODUCT_MODEL) || {}
  const { isAuthRequired, isProductModelRequired, authorities: pageAuthorities } = to.meta || {}
  let user
  let firstLogin = false

  user = await getUserInfo()

  const notAllowedRoute = process.env.VUE_APP_NOT_ALLOWED_ROUTE || ''

  const isUnderMaintenanceRoute = isRouteUnderMaintenance({
    user, path: to.path, maintenanceRoute: notAllowedRoute
  })

  if (isUnderMaintenanceRoute) {
    next({ name: NOT_ALLOWED_ROUTE.name })
  }

  if (isAuthRequired) {
    if (!(user.username)) {
      user = await signIn(to.name)
      firstLogin = true
    }

    if (!hasUserAccessToThePage({ user, pageAuthorities, to })) {
      notifyAboutDeniedAccess()
      removeCognitoFromLocalStorage()
      LocalStorage.remove(process.env.VUE_APP_STORAGE_KEY_TOKEN)
      store.commit(SET_USER, {})
      next({ name: NOT_AUTHORIZED_ROUTE.name })
    }
    else if (isProductModelRequired && !productModelId) {
      notifyAboutMissingProductModel()

      next({ name: PRODUCT_MODELS_ROUTE.name })
    }
    else if (firstLogin) {
      next({ name: HOME_ROUTE.name })
    }
    else {
      next()
    }
  }
  else {
    next()
  }
}
