const getters = {
  configurationProductModelId({ currentConfiguration = {} } = {}, moduleGetters, rootState = {}) {
    let productModelId = currentConfiguration.productModel?.id

    if (!productModelId) {
      ({ id: productModelId } = rootState['product-model']?.productModels?.find(
        productModel => productModel.encodedBusinessName === currentConfiguration.productModel?.encodedBusinessName
      ) || {})
    }

    return productModelId || ''
  },
  selectedChoicesIds: ({ selectedChoices = new Map() } = {}) => (optionId = '') => {
    const choices = selectedChoices.get(optionId) || []

    return choices.map(choice => choice.id) || []
  },
  simplifiedSelectedChoices: ({ currentConfiguration = {} } = {}) => currentConfiguration.selectedChoices?.map(
    ({ choice: { id, optionId, id2 }, locked } = {}) => ({ choice: { id, optionId, id2 }, locked })
  ) || [],
  configurationReference({ currentConfiguration = {} } = {}, moduleGetters, rootState = {}) {
    return rootState['product-model']?.productModels?.find(
      productModel => productModel.encodedBusinessName === currentConfiguration.productModel?.encodedBusinessName
    )?.reference || ''
  },
  configStructureOptionsPerProductModel:
    ({ configurationStructureOptions = [] } = []) => (productModel = '') => configurationStructureOptions
      .get(productModel),
  configSpecificationFilterOptionsPerProductModel:
    ({ configSpecificationFilterOptions = [] } = []) => (productModel = '') => configSpecificationFilterOptions
      .get(productModel),
  optionsAreLoading: ({ options = {} } = {}) => options?.areLoading,
  selectedChoices: ({ currentConfiguration, relevantDomains }) => this.simplifiedSelectedChoices(currentConfiguration)
    .filter(({ choice } = {}) => !relevantDomains.includes(choice.optionId)),
  getOptionsSize({ selectedChoices }) {
    return Array.from(selectedChoices.values())
      .filter(val => typeof val === 'string' || Array.isArray(val))
      .reduce((total, val) => total + val.length, 0)
  },
  getInvalidOptionsSize: ({ invalidOptions }) => invalidOptions.optionsSize,
  isAuthorizedToEditCurrentConfiguration({ currentConfiguration = {} } = {}, _, { user: { username } = {} } = {}) {
    const { authorizedUsers = [] } = currentConfiguration
    if (authorizedUsers.length) return authorizedUsers.includes(username)
    return true
  }
}

export default getters
