import {
  RESET_WORLD,
  SET_CATEGORIES_RESULTS,
  SET_CHOICES_RESULTS,
  SET_IS_LOADING,
  SET_OPTIONS_RESULTS,
  SET_SEARCH_CHOICES_DATA,
  SET_SEARCH_DATA,
  SET_HIGH_GROUPS,
  SET_COMPONENTS,
  SET_COMPONENT_VARIANTS
} from '@/store/modules/browse-portfolio/mutationTypes'
import cloneDeep from 'lodash.clonedeep'

function mapResults(data) {
  const allOptions = Array.from(data.values())
  return [].concat(...allOptions)
}

const mutations = {
  [SET_CATEGORIES_RESULTS](state, results) {
    state.categoriesItems.results = results
    state.categoriesItems.size = results.length
  },
  [SET_HIGH_GROUPS](state, results) {
    state.highGroups.results = results
    state.highGroups.size = results.length
  },
  [SET_COMPONENTS](state, { results, id, isActive }) {
    if (isActive) {
      state.components.categoryComponents.set(results.id, results.components)
      state.components.size += results.components.length
    }
    else {
      const opt = state.components.categoryComponents.get(id)
      state.components.size -= opt ? opt.length : 0
      state.components.categoryComponents.delete(id)
    }
    state.components.results = mapResults(state.components.categoryComponents)
  },
  [SET_OPTIONS_RESULTS](state, { results, id, isActive }) {
    if (isActive) {
      state.optionsItems.categoryOption.set(results.id, results.options)
      state.optionsItems.size += results.options.length
    }
    else {
      const opt = state.optionsItems.categoryOption.get(id)
      state.optionsItems.size -= opt ? opt.length : 0
      state.optionsItems.categoryOption.delete(id)
    }
    state.optionsItems.results = mapResults(state.optionsItems.categoryOption)
  },
  [SET_CHOICES_RESULTS](state, { isActive, id }) {
    const foundedOption = state.optionsItems.results.find(result => result.id === id)

    if (isActive) {
      state.choicesItems.size += foundedOption.choices.length
      state.choicesItems.optionChoices.set(id, foundedOption)
    }
    else {
      state.choicesItems.size -= foundedOption.choices.length
      state.choicesItems.optionChoices.delete(id)
      state.searchItems.results.delete(id)
    }

    state.choicesItems.results = mapResults(state.choicesItems.optionChoices)
  },
  [RESET_WORLD](state) {
    state.optionsItems.results = []
    state.optionsItems.categoryOption = new Map()
    state.optionsItems.size = 0

    state.choicesItems.results = []
    state.choicesItems.optionChoices = new Map()
    state.choicesItems.size = 0

    state.searchItems.results = new Map()

    state.components.results = []
    state.components.categoryComponents = new Map()
    state.components.size = 0

    state.componentVariants.results = []
    state.componentVariants.componentVariant = new Map()
    state.componentVariants.size = 0
  },
  [SET_IS_LOADING](state, data) {
    const { type, isLoading } = data
    state[type].isLoading = isLoading
  },
  [SET_SEARCH_DATA](state, data) {
    if (!state.searchItems.results.has(data.id)) {
      state.searchItems.results.set(data.id, data)
    }
    else {
      state.searchItems.results.delete(data.id)
    }
  },
  [SET_SEARCH_CHOICES_DATA](state, data) {
    const { item, subItem } = data

    if (!state.searchItems.results.has(item.id)) {
      const newSearchOption = cloneDeep(item)
      newSearchOption.choices = []
      newSearchOption.componentVariants = []
      state.searchItems.results.set(item.id, newSearchOption)
    }

    const { id } = subItem
    const savedItem = cloneDeep(state.searchItems.results.get(item.id))

    const { choices, componentVariants } = savedItem
    const optionTarget = savedItem.componentId ? componentVariants : choices
    const index = optionTarget.findIndex(choice => choice.id === id)

    index !== -1 ? optionTarget.splice(index, 1) : optionTarget.push(subItem)

    if (optionTarget.length) {
      state.searchItems.results.set(savedItem.id, savedItem)
    }
    else {
      state.searchItems.results.delete(savedItem.id)
    }
  },
  [SET_COMPONENT_VARIANTS](state, { isActive, id }) {
    const foundedOption = state.components.results.find(result => result.id === id)

    if (isActive) {
      state.componentVariants.size += foundedOption.componentVariants.length
      state.componentVariants.componentVariant.set(id, foundedOption)
    }
    else {
      state.componentVariants.size -= foundedOption.componentVariants.length
      state.componentVariants.componentVariants.delete(id)
      state.searchItems.results.delete(id)
    }

    state.componentVariants.results = mapResults(state.componentVariants.componentVariant)
  }
}

export default mutations
