import {
  ON_DEMAND_EXPORTS_ROUTE
} from '@/router/routeNames'

const OnDemandExports = () => import(
  /* webpackChunkName: "test-cases" */ '@/components/rule-validation/on-demand-exports/OnDemandExports'
)

const routes = [
  {
    path: ON_DEMAND_EXPORTS_ROUTE.path,
    name: ON_DEMAND_EXPORTS_ROUTE.name,
    component: OnDemandExports,
    meta: {
      appFeatureSubRoute: ON_DEMAND_EXPORTS_ROUTE,
      title: ON_DEMAND_EXPORTS_ROUTE.title
    }
  }
]

export default routes
